<template>
  <div class="basic_main">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="危化品名录" name="first">
        <weihuapinmingluVue></weihuapinmingluVue>




      </el-tab-pane>
      <el-tab-pane label="数据字典" name="second">
        <shujuzidianVue></shujuzidianVue>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import shujuzidianVue from './basicdata/shujuzidian.vue';
import weihuapinmingluVue from './basicdata/weihuapinminglu.vue';



export default {
  name: 'BasicMain',
  components: {
    weihuapinmingluVue,
    shujuzidianVue
  },
  data() {
    return {
      activeName: "first",
      form: {
        name: '', // 品名
        alias: '', // 别名
        cas: '', // CAS号
        remark: '' // 备注
      }
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
};
</script>

<style scoped>
.basic_main {
  text-align: left;
  padding: 20px;
  background: #f8f8f8;
}
</style>