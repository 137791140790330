<!-- TableDialog.vue -->
<template>
  <div style="text-align: left">
    <el-dialog
      @close="$parent.closeUser"
      :visible.sync="visible"
      title="角色用户列表"
      top="15vh"
      :close-on-click-modal="false"
    >
      <div>
        <el-button type="text" @click="openAddUserDialog" icon="el-icon-plus"
          >添加用户</el-button
        >
      </div>
      <el-table
        :data="currentPageData"
        style="width: 100%"
        width="100%"
        height="500"
      >
        <el-table-column prop="uid" label="序号" width="80"></el-table-column>
        <el-table-column
          prop="role_name"
          label="角色"
          width="200"
        ></el-table-column>
        <el-table-column prop="name" label="用户"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              style="color: #409eff"
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        @current-change="handlePageChange"
        style="margin-top: 10px"
      ></el-pagination>
    </el-dialog>

    <!-- 用户列表 -->
    <select-user
      :visible.sync="listUserVisible"
      multiple
      :select-rows-value="selectUserIds"
      @select="onSelectUser"
    ></select-user>
  </div>
</template>

<script>
import selectUser from "./select-user.vue";
export default {
  components: {
    selectUser,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    role_id: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      addUserDialogVisible: false,
      selectedRole: {},
      newUser: {
        user_id: "",
      },
      listUserVisible: false,
      userAllTableData: [],
    };
  },
  watch: {
    role_id(val) {
      this.getRoleList();
    },
  },
  async created() {},
  computed: {
    currentPageData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableData.slice(start, end);
    },
    selectUserIds() {
      return this.userAllTableData.map((item) => item.id);
    },
  },
  methods: {
    async deleteUser(row) {
      // const res = await this.$dbquery(
      //   this.$http,
      //   "DELETE FROM xtbg_hazard_role_users WHERE id=" + row.id
      // );

      const res = await this.$db_delete(
        this.$http,
        "xtbg_hazard_role_users",
        "id=" + row.id
      );

      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
          offset: 200,
        });
        this.getRoleList();
      } else {
        this.$message({
          showClose: true,
          message: "删除失败",
          type: "error",
          offset: 200,
        });
      }
    },
    async getRoleList() {
      // const data = await this.$dbquery(
      //   this.$http,
      //   `SELECT ju.*,j.role_name,u.name FROM xtbg_hazard_role_users ju
      //       LEFT JOIN xtbg_hazard_roles j ON j.id=ju.role_id
      //       LEFT JOIN xtbg_user u ON u.id=ju.user_id where role_id=` +
      //     this.role_id
      // );
      const data = await this.$db_select(
        this.$http,
        `xtbg_hazard_role_users ju
            LEFT JOIN xtbg_hazard_roles j ON j.id=ju.role_id
            LEFT JOIN xtbg_user u ON u.userid=ju.userid`,
        "ju.*,j.role_name,u.name",
        "role_id=" + this.role_id
      );
      let index = 0;
      this.tableData = data.map((row) => {
        index++;
        return {
          ...row,
          uid: index,
        };
      });
      this.total = data.length; // 设置数据总数
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    openAddUserDialog(role) {
      this.selectedRole = role;
      this.newUser.user_id = "";
      this.listUserVisible = true;
    },
    addUser() {
      this.listUserVisible = true;
    },
    async getRoleAllUser() {
      const param =
        `SELECT *  ${this.userQueryWrapper} AND id IN (SELECT user_id FROM ` +
        congress_role_user +
        ` WHERE role_id = '${this.selectRole.id}')`;

      const res = await this.$dbquery(this.$http, param);
      this.userAllTableData = res || [];
    },
    async onSelectUser(rows) {
      const role_id = 1;
      //插入到数据库 然后刷新

      //console.log(rows);

      const promises = rows.map(async (row) => {
        // const sres = await this.$dbquery(
        //   this.$http,
        //   "select * from xtbg_hazard_role_users where user_id=" + row.id
        // );
        const sres = await this.$db_select(
          this.$http,
          "xtbg_hazard_role_users","*",`userid='${row.userid}' and role_id=${this.role_id}`
        );
        //console.log(sres);
        if (sres.length == 0) {
          // const insertSql =
          //   "insert into xtbg_hazard_role_users(user_id,role_id) values(" +
          //   row.id +
          //   "," +
          //   this.role_id +
          //   ")";

          const inesrtRes =  await this.$db_insert(this.$http, "xtbg_hazard_role_users", {
            user_id: row.user_id,
            userid: row.userid,
            role_id: this.role_id,
          });
          //console.log("插入接口",inesrtRes);

          // return await this.$dbquery(this.$http, insertSql);
        }
      });
      const result = await Promise.all(promises);
      //console.log(result);
      this.getRoleList();
    },
  },
};
</script>
