<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
    id="dialog_shenling"
    :show-close="true"
    :fullscreen="true"
    @close="close"
  >
    <div slot="title" class="dialog-title">
      <el-row>
        <el-button type="text" @click="close" icon="el-icon-arrow-left"
          >返回</el-button
        >
        <span class="dialog-title-text" style="padding-left: 10px"
          >危化品申领审批</span
        >
      </el-row>
    </div>
    <!-- 审批记录 -->
    <div class="sl_records">
      <h3 class="section-title">审批记录</h3>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        border
        height="82vh"
      >
        <el-table-column
          prop="uid"
          label="序号"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="申领药品"></el-table-column>
        <el-table-column prop="spec" label="规格"></el-table-column>
        <el-table-column prop="weight" label="领用量"></el-table-column>

        <el-table-column
          prop="create_username"
          label="申领人"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          sortable
          label="申领时间"
        ></el-table-column>

        <el-table-column prop="status" sortable label="申领状态">
          <template slot-scope="scope">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <el-steps
                  direction="vertical"
                  :active="getActiveStep(scope.row)"
                >
                  <el-step
                    :title="scope.row.create_username"
                    :description="'提交申领-' + scope.row.create_time"
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.group_user_id
                        ? scope.row.group_username
                        : '研究组负责人'
                    "
                    :description="
                      scope.row.group_user_id
                        ? scope.row.guser_result +
                          ' - ' +
                          scope.row.guser_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.admin_user_id
                        ? scope.row.admin_username
                        : '管理员'
                    "
                    :description="
                      scope.row.admin_user_id
                        ? scope.row.admin_result +
                          ' - ' +
                          scope.row.admin_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.admin_result)"
                  ></el-step>
                </el-steps>
              </div>
              <span>
                <span
                  v-if="scope.row.status === 0 || scope.row.status === null"
                  style="color: #f00"
                  >待审核</span
                >
                <span style="color: #085" v-if="scope.row.status === 1"
                  >待确认</span
                >
                <span style="color: #085" v-if="scope.row.status === 2"
                  >待上传照片</span
                >
                <span style="color: #085" v-if="scope.row.status === 3"
                  >已上传照片</span
                >
                <span style="color: #085" v-if="scope.row.status === 4"
                  >已退回</span
                >
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click="yjzzzShenHe(scope.row)"
              type="text"
              icon="el-icon-view"
              size="small"
              v-if="
                scope.row.admin_user_id == null || scope.row.admin_user_id == ''
              "
              >审核</el-button
            >
            <div v-else>
              <el-button
                type="text"
                size="small"
                icon="el-icon-check"
                style="color: #085"
                >已审核</el-button
              >
              <el-button
                type="text"
                size="small"
                icon="el-icon-view"
                v-if="scope.row.status == 1"
                @click="viewQuerenShenling(scope.row)"
                >查看</el-button
              >
              <el-button
              type="text"
              size="small"
              icon="el-icon-view"
              v-if="scope.row.status == 1"
              @click="viewOkQuerenShenling(scope.row)"
              >确认</el-button
            >
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    

    <!-- 查看页面 -->
    
    <el-drawer
      :visible="querenShDialog"
      :modal="false"
      width="30%"
      id="yjzShDialog"
      :show-close="false"
    >
      <div>
        <el-form style="padding: 0px 20px;">
          <el-row>
            <h3 class="section-title">申领信息</h3>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="药品:">
                <span
                  style="
                    color: #000;
                    text-decoration: underline;
                    cursor: pointer;
                    color: #085;
                  "
                  @click="querenShowYaopin"
                  >{{ querenForm.info_name }}</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="零用量:">
                <span style="color: #000">{{ querenForm.weight }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="规格:">
                <span style="color: #000">{{ querenForm.info_spec }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="领用用途:">
                <span style="color: #000">{{ querenForm.purpose }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="申领人:">
                <span style="color: #000">{{
                  querenForm.create_username
                }}</span>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
              <el-form-item label="审核意见">
                <el-input
                  style="color: #000; font-size: 15px;"
                  type="textarea"
                  v-model="querenForm.admin_remark"
                  placeholder="请输入审核意见"
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
      </div>

      <div class="dialog-footer">
        <el-button
          type="text"
          @click="okShDialoghandleClose"
          icon="el-icon-arrow-left"
          style="float: left;margin-left: 20px;"
          >返回</el-button
        >
      
      </div>
    </el-drawer>

    <!-- 审核页面 -->
    <el-drawer
      :visible="yjzShDialog"
      :modal="false"
      width="30%"
      
      :show-close="false"
    >
      <div>
        <el-form style="padding: 0 20px">
          <el-row>
            <h3 class="section-title">申领信息</h3>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="药品:">
                <span
                  style="
                    color: #000;
                    text-decoration: underline;
                    cursor: pointer;
                    color: #085;
                  "
                  @click="showYaopin"
                  >{{ ShenlingForm.info_name }}</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="零用量:">
                <span style="color: #000">{{ ShenlingForm.weight }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="规格:">
                <span style="color: #000">{{ ShenlingForm.info_spec }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="领用用途:">
                <span style="color: #000">{{ ShenlingForm.purpose }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="申领人:">
                <span style="color: #000">{{
                  ShenlingForm.create_username
                }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="组长审核意见"></el-form-item>
                <el-input
                  style="color: #000; font-size: 15px"
                  type="text"
                  disabled="true"
                  v-model="ShenlingForm.guser_remark"
                  read-only="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="审核意见">
                <el-input
                  style="color: #000; font-size: 15px"
                  type="textarea"
                  v-model="ShenlingForm.admin_remark"
                  placeholder="请输入审核意见"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="dialog-footer">
        <el-button
          type="default"
          @click="yjzShDialoghandleClose"
          icon="el-icon-close"
          >取消</el-button
        >
        <el-button type="success" @click="approveShenling" icon="el-icon-check"
          >通过</el-button
        >
        <el-button type="danger" @click="rejectTuihui" icon="el-icon-back"
          >退回</el-button
        >
      </div>
    </el-drawer>
    <showdetailVue
      :showDetail="showDetail"
      @close="showDetail = false"
      :show_id="show_id"
    ></showdetailVue>
  </el-dialog>
</template>

<script>
import showdetailVue from "./showdetail.vue";

export default {
  props: {
    dialogVisible: Boolean,
    isShowShenlingAndHeXiaoShenPin: Boolean,
  },
  components: {
    showdetailVue,
  },
  data() {
    return {
      pageSize: 20,
      total: 0,
      curent_index: 1,

      loading: true,
      formData: {
        name: "",
        info_id: "",
        weight: "",
        purpose: "",
        create_by: null,
      },
      ShenlingForm: {
        id: null,
        info_id: "",
        info_name: "",
        info_spec: "",
        weight: "",
        purpose: "",
        create_by: null, //领用人
        create_username: "",
        admin_remark: "同意申领",
        guser_remark:'',
        create_userid:'',
        group_id:'',
      },
      yjzShDialog: false,
      current_stock: "",
      create_name: localStorage.getItem("username"),
      names: [],
      allnames: [],
      rules: {
        name: [{ required: true, message: "请选择药品", trigger: "blur" }],
        weight: [{ required: true, message: "请输入领用量", trigger: "blur" }],
        purpose: [
          { required: true, message: "请输入领用用途", trigger: "blur" },
        ],
        create_by: [
          { required: true, message: "请输入领用人", trigger: "blur" },
        ],
      },
      querenShDialog: false,
      querenForm: {
        id: null,
        info_id: "",
        info_name: "",
        info_spec: "",
        weight: "",
        purpose: "",
        create_by: null, //领用人
        create_username: "",
        admin_remark: "同意申领",
      },

      tableData: [],
      showDetail: false,
      show_id: null,

      current_info: {},
      info_group_form: {
        groupid: "", // 所在研究组
        name: "", // 药品名称
        cas: "", // CAS号
        controlled: false, // 是否管制内药品
        attribute: "", // 药品属性
        purchase: "", // 采购时间
        sales: "", // 销售公司
        manufacturer: "", // 厂家
        spec: "", // 规格
        quantity: "", // 数量
        shelf_life: "", // 保质期
        park: "", // 所在园区
        study_groupid: "", // 所在研究组
        room: "", // 存放房间
        manage1_userid: "", // 主管理员1
        manage2_userid: "", // 副管理员2
        stock_in_time: "", // 入库时间
        stock_in: "", // 入库量
        stock_out_time: "", // 出库时间
        stock_out: "", // 出库量
        stock: "", // 库存量
        use_userid: "", // 领用人
        purpose: "", // 领用用途
        belongto: "", // 所属管理
        remark: "", // 备注
        create_by: "", // 创建人
        create_time: "", // 创建时间
        update_by: "", // 修改人
        update_time: "", // 修改时间
        units: "", // 单位
        purity: "", // 纯度
        info_id: "", // 核销药品id
        application_id: "", // 申领ID
      },
      curent_application: {}
    };
  },
  async created() {
    // let shenheData = await this.$db_select(this.$http, "");
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getSlList();
      }
    },
  },
  methods: {
    async viewOkQuerenShenling(row){
      const upres = await this.$db_update(this.$http,"xtbg_hazard_application",{status:2},"id="+row.id);
      if(upres.affectedRows>0){
        this.$message({
          type: "success",
          message: "确认领取",
        });
      }else{
        this.$message({
          type: "error",
          message: "确认失败",
        });
      }
      await this.getSlList();

    },
    currentChange(index) {
      this.curent_index = index;
      this.getSlList();
    },
    okShDialoghandleClose() {
      this.querenShDialog = false;
    },
    async okApproveShenling() {
      for (let key in this.current_info) {
        for (key in this.info_group_form) {
          this.info_group_form[key] = this.current_info[key];
        }
      }
      this.info_group_form.application_id = this.curent_application.id;
      this.info_group_form.groupid = this.curent_application.group_id;
      this.info_group_form.info_id = this.curent_application.info_id;
      this.info_group_form.create_by = this.curent_application.create_by;
      this.info_group_form.create_time = this.$FormatDateToYMDHMS(
        new Date(),
        true
      );
      this.info_group_form.purchase = this.$FormatDateToYMDHMS(
        this.current_info.purchase,
        true
      );
      this.info_group_form.stock_in_time = this.$FormatDateToYMDHMS(
        this.current_info.stock_in_time,
        true
      );

      console.log(this.info_group_form);

      const insertRes = await this.$db_insert(
        this.$http,
        "xtbg_hazard_info_group",
        this.info_group_form
      );

      if (insertRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "成功入库到该研究组药品库中",
        });
      } else {
        this.$message({
          type: "error",
          message: "入库到该研究组药品库失败",
        });
      }

      let form = {
        status: 2,
      };
      const result = await this.$db_update(
        this.$http,
        "xtbg_hazard_application",
        form,
        "id=" + this.querenForm.id
      );

      if (result.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "确认成功",
        });

        //并且 入库到研究的药品表

        this.yjzShDialog = false;
      } else {
        this.$message({
          type: "error",
          message: "确认失败",
        });
        this.yjzShDialog = false;
      }
      this.getSlList();
      this.okShDialoghandleClose();
    },
    async viewQuerenShenling(row) {
    
      this.curent_application = row;
      const yp_info = await this.$db_select(
        this.$http,
        "xtbg_hazard_info",
        "*",
        "id=" + row.info_id
      );
      this.current_info = yp_info[0];
      // console.log(this.current_info);
      row.info_name = yp_info[0].name;
      row.info_spec = yp_info[0].spec;

      this.querenShDialog = true;
      this.querenForm = row;
    },

    yjzShDialoghandleClose() {
      this.yjzShDialog = false;
    },
    showYaopin() {
      this.show_id = this.ShenlingForm.info_id;

      this.showDetail = true;
    },
    querenShowYaopin() {
      this.show_id = this.querenForm.info_id;

      this.showDetail = true;
    },
    closeShowYaopin() {
      this.showDetail = false;
    },
    async yjzzzShenHe(row) {

      //console.log(row);
      for (let key in row) {
        if (key in this.ShenlingForm) {
          this.ShenlingForm[key] = row[key];
        }
      }
      //药品信息
      const yp_info = await this.$db_select(
        this.$http,
        "xtbg_hazard_info",
        "name,spec",
        "id=" + row.info_id
      );
      if(yp_info&&yp_info.length>0){
        this.ShenlingForm.info_name = yp_info[0].name;
      this.ShenlingForm.info_spec = yp_info[0].spec;

      const slr_info = await this.$db_select(
        this.$http,
        "xtbg_user",
        "name",
        `userid='${row.create_userid}'` 
      );
      this.ShenlingForm.create_username = slr_info[0].name;

      this.yjzShDialog = true;

      }else{
        this.$message.error("药品信息获取失败");
      }

    
      
     
    },
    async approveShenling() {
      this.ShenlingForm.result = "通过";

     

      let shForm = {
        admin_userid: localStorage.getItem("userid"),
        admin_user_id: localStorage.getItem("userid2"),
        admin_aptime: this.$FormatDateToYMDHMS(new Date(), true),
        admin_result: "通过",
        admin_remark: this.ShenlingForm.admin_remark,
        status: 1,
      };

      const yapInfoRes = await this.$db_select(
          this.$http,"xtbg_hazard_info","*",
          "id="+this.ShenlingForm.info_id
        );

        let info_group = {
            groupid: null,
            name: '',
            cas: '',
            controlled: false,
            attribute: '',
            purchase: null,
            sales: null,
            manufacturer: '',
            spec: '',
            quantity: 0,
            factory_time: null,
            shelf_life: null,
            park: '',
            study_groupid: null,
            room: '',
            manage1_userid: null,
            manage2_userid: null,
            stock_in_time: null,
            stock_in: 0,
            stock_out_time: null,
            stock_out: 0,
            stock: 0,
            use_userid: null,
            purpose: '',
            belongto: '',
            bcode: '',
            remark: '',
            create_userid: null,
            create_by: '',
            create_time: null,
            update_by: '',
            update_time: null,
            units: '',
            purity: '',
            info_id: null,
            application_id: null,
            status: ''

					};

        for(let key in info_group){
           info_group[key]=yapInfoRes[0][key];
        }
        
        info_group.create_time=this.$FormatDateToYMDHMS(new Date(),true);
        info_group.stock_in_time=this.$FormatDateToYMDHMS(new Date(),true);
        info_group.quantity=this.ShenlingForm.weight;
        info_group.stock=this.ShenlingForm.weight;
        info_group.stock_in=this.ShenlingForm.weight;
        info_group.create_userid = this.ShenlingForm.create_userid;
        info_group.groupid = this.ShenlingForm.group_id;
        info_group.info_id= this.ShenlingForm.info_id;
        info_group.application_id = this.ShenlingForm.id;
        info_group.status = 0;
        info_group.purchase = this.$FormatDateToYMDHMS(info_group.purchase,false);
        info_group.factory_time = this.$FormatDateToYMDHMS(info_group.factory_time,false);
        delete info_group.stock_out_time;
        delete info_group.update_time;
        info_group.qrcode= await this.$generateQRCodeBase64(JSON.stringify(info_group));

        

    
      
      // return;
      const result = await this.$db_update(
        this.$http,
        "xtbg_hazard_application",
        shForm,
        "id=" + this.ShenlingForm.id
      );
     
      if (result.affectedRows > 0) {


        //添加研究组的药品记录

        const insertGroupInfoRes = await this.$db_insert(this.$http,"xtbg_hazard_info_group",info_group);
        console.log(insertGroupInfoRes);



        // const insertGnfoRes = await this.$db_update(this.$http,"xtbg_hazard_info",{stock:stock-this.ShenlingForm.weight},"id="+this.ShenlingForm.info_id);

        // console.log(insertGnfoRes);



        this.$message({
          type: "success",
          message: "审核成功",
        });
        this.yjzShDialog = false;
      } else {
        this.$message({
          type: "error",
          message: "审核失败",
        });
        this.yjzShDialog = false;
      }
      this.getSlList();
    },
    async rejectTuihui() {
      let shForm = {
        admin_userid: localStorage.getItem("userid"),
        admin_aptime: this.$FormatDateToYMDHMS(new Date(), true),
        admin_result: "未通过",
        admin_remark: this.ShenlingForm.guser_remark,
        status: 4,
      };
      const result = await this.$db_update(
        this.$http,
        "xtbg_hazard_application",
        shForm,
        "id=" + this.ShenlingForm.id
      );
      console.log(result);
      if (result.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "审核成功",
        });
        this.yjzShDialog = false;
      } else {
        this.$message({
          type: "error",
          message: "审核失败",
        });
        this.yjzShDialog = false;
      }
      this.getSlList();
    },

    getStepStatus(result) {
      if (result === "通过") return "success";
      if (result === "未通过") return "error";
      return "process";
    },
    getActiveStep(row) {
      if (row.admin_result === "通过" || row.admin_result === "未通过") {
        return 2;
      } else if (row.guser_result === "通过" || row.guser_result === "未通过") {
        return 1;
      } else {
        return 0;
      }
    },

    reset() {
      this.formData = {
        info_id: "",
        spec: "",
        weight: "",
        purpose: "",
        create_by: null,
        status: 0,
      };
    },
    querySearch(queryString, cb) {
      var restaurants = this.names;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      //this.formData.cas = item.cas;
      // 在这里处理选中的项目\
      console.log(item);

      this.formData.info_id = item.id;

      this.current_stock = item.stock;

      this.formData.spec = item.spec;
      this.$refs.formData.validateField("name");
    },
    handleMedicineSelect() {
      // 处理药品选择的逻辑
      console.log("选择药品");
    },
    close() {
      this.$parent.closeDialogShenling();
    },
    async getSlList() {
      // this.loading = true;

      // const res = await this.$db_select(
      //   this.$http,
      //   "xtbg_hazard_application",
      //   "*",
      //   "guser_result='通过'  order by create_time DESC limit " +
      //     (this.curent_index - 1) * this.pageSize +
      //     "," +
      //     this.pageSize
      // );
      // let row_index = 0;
      // this.tableData = await Promise.all(

      //   res.map(async (row) => {
      //     row_index++;
      //     row.uid = row_index;
      //     if (row.group_userid) {
      //       row.group_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.group_userid
      //         )
      //       )[0].name;
      //       row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);
      //     }
      //     if (row.admin_userid) {
      //       row.admin_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.admin_userid
      //         )
      //       )[0].name;
      //       row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);
      //     }

      //     return {
      //       ...row,
      //       name: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_hazard_info",
      //           "name",
      //           "id=" + row.info_id
      //         )
      //       )[0].name,
      //       create_username: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.create_by
      //         )
      //       )[0].name,
      //       create_time: this.$FormatDateToYMDHMS(row.create_time, true),
      //     };
      //   })
      // );
      // this.loading = false;

      // const totalRes = await this.$db_select(this.$http,"xtbg_hazard_application","count(*) as total","guser_result='通过'");
      // this.total = totalRes[0].total;

      this.loading = true;

      try {
        // 获取符合条件的数据
        const res = await this.$db_select(
          this.$http,
          "xtbg_hazard_application",
          "*",
          "guser_result='通过' order by create_time DESC limit " +
            (this.curent_index - 1) * this.pageSize +
            "," +
            this.pageSize
        );

        if (!res || res.length === 0) {
          this.tableData = [];
          this.loading = false;
          return;
        }

        // 提取所有可能的 group_userid、admin_userid、create_by 和 info_id
        // const groupUserIds = [
        //   ...new Set(res.map((row) => row.group_userid).filter(Boolean)),
        // ];
        // const adminUserIds = [
        //   ...new Set(res.map((row) => row.admin_userid).filter(Boolean)),
        // ];
        // const createByIds = [
        //   ...new Set(res.map((row) => row.create_by).filter(Boolean)),
        // ];
        // const infoIds = [
        //   ...new Set(res.map((row) => row.info_id).filter(Boolean)),
        // ];

        const groupUserIds = [
          ...new Set(res.map((row) => row.group_user_id).filter(Boolean)),
        ];
        const adminUserIds = [
          ...new Set(res.map((row) => row.admin_user_id).filter(Boolean)),
        ];
        const createByIds = [
          ...new Set(res.map((row) => row.create_userid).filter(Boolean)),
        ];
        const infoIds = [
          ...new Set(res.map((row) => row.info_id).filter(Boolean)),
        ];

        // 合并所有用户 ID，避免重复查询
        const userIds = [
          ...new Set([...groupUserIds, ...adminUserIds, ...createByIds]),
        ];

        const useridses = userIds.map(row=>`'${row}'`).join(",");
        // return;

        // 批量查询所有相关信息
        const [users, infos] = await Promise.all([
          userIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_user",
                "id, name, userid",
                `userid IN (${useridses})`
              )
            : [],
          infoIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_hazard_info",
                "id, name",
                `id IN (${infoIds.join(",")})`
              )
            : [],
        ]);
       // console.log(users);

        // 构建 ID 到名称的映射
        const userMap = Object.fromEntries(
          users.map((user) => [user.userid, user.name])
        );
       // console.log(userMap);
        const infoMap = Object.fromEntries(
          infos.map((info) => [info.id, info.name])
        );

        // 处理数据
        let row_index = 0;
        this.tableData = res.map((row) => {
          row_index++;
          row.uid = row_index;

          row.group_username = userMap[row.group_user_id] || "";
          row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);

          row.admin_username = userMap[row.admin_user_id] || "";
          row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);

          row.name = infoMap[row.info_id] || "";
          row.create_username = userMap[row.create_userid] || "";
          row.create_time = this.$FormatDateToYMDHMS(row.create_time, true);

          return row;
        });

        // 获取总数
        const totalRes = await this.$db_select(
          this.$http,
          "xtbg_hazard_application",
          "count(*) as total",
          "guser_result='通过'"
        );
        this.total = totalRes[0]?.total || 0;
      } catch (error) {
        console.error("加载数据时发生错误:", error);
        this.$message.error("加载数据时发生错误");
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteClick(row) {
      if (
        this.isShowShenlingAndHeXiaoShenPin &&
        row.admin_user_id != "" &&
        row.admin_user_id != null
      ) {
        console.log(44);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      if (row.group_userid != "" && row.group_userid != null) {
        console.log(66);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      //删除
      const deleteRes = await this.$db_delete(
        this.$http,
        "xtbg_hazard_application",
        "id=" + row.id
      );
      if (deleteRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        await this.getSlList();
      } else {
        this.$message({
          type: "error",
          message: "删除失败",
        });
      }
    },
    handleSubmit() {
      // 处理表单提交逻辑
      //console.log("表单提交", this.formData);
      //this.dialogVisible = false;

      this.formData.create_by = localStorage.getItem("userid");
      if (this.formData.weight == 0) {
        this.$message({
          type: "warning",
          message: "领用量不能为0",
        });
        return false;
      }
      if (this.formData.weight > this.current_stock) {
        this.$message({
          type: "warning",
          message: "领用量不能大于库存量",
        });
        return false;
      }

      delete this.formData.name;

      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          //开始提交
          this.formData.create_time = this.$FormatDateToYMDHMS(
            new Date(),
            true
          );
          //console.log(this.formData);

          // 判断是否是负责人
          if (this.isShowShenlingAndHeXiaoShenPin) {
            this.formData.group_userid = localStorage.getItem("userid");
            this.formData.group_user_id = localStorage.getItem("userid2");
            this.formData.guser_result = "通过";
            this.formData.guser_remark = "通过";
            this.formData.guser_aptime = this.$FormatDateToYMDHMS(
              new Date(),
              true
            );
          }

          const insertRes = await this.$db_insert(
            this.$http,
            "xtbg_hazard_application",
            this.formData
          );
          //console.log(insertRes);
          if (insertRes.affectedRows > 0) {
            this.reset();
            this.$message({
              type: "success",
              message: "申领成功!",
            });
            this.getSlList();
          } else {
            this.$message({
              type: "error",
              message: "申领失败!",
            });
          }
        } else {
          console.log("表单验证失败");
        }
      });
    },
  },
  async mounted() {
    this.reset();
    this.allnames = await this.$db_select(this.$http, "xtbg_hazard_info", "*");

    this.names = this.allnames.map((item) => {
      if (item.cas) {
        item.name = item.name + "(" + item.cas + ")";
        item.label = item.name + "(" + item.cas + ")";
      }

      return {
        id: item.id,
        value: item.name,
        label: item.label,
        cas: item.cas,
        stock: item.stock,
        spec: item.spec,
      };
    });

    //申领记录
  },
};
</script>

<style scoped>
.steps-tooltip {
  width: 300px;
  white-space: normal;
}
.section {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f7fa;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #67c23a;
  margin-bottom: 10px;
  border-left: 4px solid #67c23a;
  padding-left: 10px;
}

.medicine-info {
  display: flex;
  align-items: center;
}

.medicine-info span {
  flex-grow: 1;
}

.inventory-status {
  color: red;
  margin-left: 10px;
}

.dialog-footer {
  text-align: right;
  padding: 10px 0;
}

.sl_records {
  /* display: flex;
  flex-direction: column; */

}
</style>
