<template>
  <div class="container" id="list_hazard">
    <div
      class="search-bar"
      style="
        width: 100%;
        display: flex;
        border-bottom: 2px solid #fff;
        padding-bottom: 20px;
        margin-bottom: 20px;
      "
    >
      <div>
        <el-radio-group v-model="yaopintype" size="mini" @change="chooseYaopin">
          <div v-if="isAdmin">
            <el-radio-button label="全部危化品"></el-radio-button>
            <el-radio-button label="研究组危化品"></el-radio-button>
          </div>
          <div v-else-if="isAnquanyuan">
            <el-radio-button label="全部危化品"></el-radio-button>
            <el-radio-button label="研究组危化品"></el-radio-button>
          </div>
          <div v-else-if="isShowShenlingAndHeXiaoShenPin">
            <el-radio-button label="研究组危化品"></el-radio-button>
          </div>
          <div v-else-if="!isMyAdmin">
            <el-radio-button label="研究组危化品"></el-radio-button>
          </div>
          <div v-else>
            <el-radio-button label="研究组危化品"></el-radio-button>
          </div>
        </el-radio-group>
      </div>
      <div style="margin-left: auto; width: 30%; margin-right: 10px">
        <el-autocomplete
          v-model="searchQuery"
          :fetch-suggestions="querySearch"
          placeholder="药品名称"
          prefix-icon="el-icon-search"
          @select="handleSelect"
          :trigger-on-focus="false"
          style="width: 100%"
          class="search-input"
        />
      </div>

      <div style="display: flex">
        <el-button
          type="text"
          @click="startApplication"
          v-if="isAnquanyuan"
          icon="el-icon-plus"
          style="
            background: #fafafa !important;
            cursor: pointer;
            color: black;
            border: none;
            margin-left: 10px;
            display: flex;
            border-radius: 5px;
          "
        >
          <!-- <i class="el-icon-plus" style="color: #008500"></i> -->

          <span>申领</span>
        </el-button>
        <el-button
          type="text"
          @click="startHexiao"
          v-if="isAnquanyuan"
          icon="el-icon-close"
          style="
            background: #fafafa !important;
            cursor: pointer;
            color: black;
            border: none;
            display: flex;
            border-radius: 5px;
          "
        >
          <span>核销</span>
        </el-button>
        <el-button
          type="text"
          @click="addHazard"
          icon="el-icon-plus"
          v-if="isShowRuKuData"
          style="
            background: #fafafa !important;
            cursor: pointer;
            color: black;
            border: none;

            display: flex;
            border-radius: 5px;
          "
        >
          <span>入库</span>
        </el-button>
        <el-button
          type="text"
          @click="viewApplications"
          v-if="isShowShenlingAndHeXiaoShenPin"
          icon="el-icon-tickets"
          style="
            background: #fafafa !important;
            cursor: pointer;
            color: black;
            border: none;

            display: flex;
            border-radius: 5px;
          "
        >
          申领审批
        </el-button>
        <el-button
          type="text"
          @click="viewHexiaoShenpin"
          v-if="isShowShenlingAndHeXiaoShenPin"
          icon="el-icon-tickets"
          style="
            background: #fafafa !important;
            cursor: pointer;
            color: black;
            border: none;

            display: flex;
            border-radius: 5px;
          "
        >
          核销审批
        </el-button>
      </div>
    </div>

    <div class="item-grid">
      <div
        class="item"
        v-for="(item, uid) in filteredItems"
        :key="uid"
        style="position: relative"
      >
        <div
          style="
            position: absolute;
            top: -12px;
            left: 0px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;

            width: 24px;
            /* border-radius: 28px; */
            border: 1px solid #ebeef5;
            z-index: 2;
            background: #519f6d;
            color: #fff;
          "
        >
          {{ uid + 1 }}
        </div>
        <el-card class="box-card">
          <div class="item-info" style="position: relative">
            <p
              style="display: flex; padding: 0px 5px !important"
              :class="[
                'item-title-active',
                { active: item.controlled && item.controlled != '其他' },
              ]"
            >
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
                >药品名称</span
              >
              <span style="font-weight: 500; color: #303133">{{
                item.name
              }}</span>
            </p>
            <p style="display: flex; padding: 0px 5px !important">
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
              >
                药品属性</span
              ><span style="font-weight: 500; color: #303133">{{
                item.attribute
              }}</span>
            </p>
            <p style="display: flex; padding: 0px 5px !important">
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
              >
                CAS号</span
              ><span style="font-weight: 500; color: #303133">{{
                item.cas
              }}</span>
            </p>
            <p style="display: flex; padding: 0px 5px !important">
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
                >药品规格</span
              >
              <span style="font-weight: 500; color: #303133">
                {{ item.spec }}</span
              >
            </p>
            <!-- <p style="display: flex; padding: 0px 5px !important">
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
                >纯度</span
              >
              <span style="font-weight: 500; color: #303133">
                {{ item.purity }}</span
              >
            </p> -->
            <p style="display: flex; padding: 0px 5px !important">
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
                >保质期</span
              >
              <span style="font-weight: 500; color: #303133">
                {{ item.shelf_life }}</span
              >
            </p>

            <p style="display: flex; padding: 0px 5px !important">
              <span
                style="
                  display: block;
                  text-align: left;
                  color: #a6a6a6;
                  width: 60px;
                "
                >库存量：</span
              >
              <span style="font-weight: 500; color: #303133">
                {{ item.stock_in }}{{ item.units }}</span
              >
            </p>
          </div>
          <div class="item-actions">
            <div style="margin-left: auto" class="weihuapin_btns">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-news"
                @click="applyForItem(item)"
                v-if="isSHowShenlingAndHeXiao && yaopintype == '全部危化品'"
                >申领</el-button
              >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="editHazard(item)"
                v-if="isEditAndDeleteEnabled"
                >修改</el-button
              >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-delete"
                @click="deleteHazard(item)"
                style="color: #f00"
                v-if="isEditAndDeleteEnabled && yaopintype != '研究组危化品'"
                >删除</el-button
              >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-delete"
                @click="hexiaoHazard(item)"
                style="color: #f00"
                v-if="yaopintype == '研究组危化品' && isSHowShenlingAndHeXiao"
                >核销</el-button
              >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-s-operation"
                @click="viewDetails(item)"
                style="color: #085"
                v-if="
                  (yaopintype == '全部危化品' && isAnquanyuan == false) ||
                  isAdmin ||
                  (isAnquanyuan && yaopintype == '研究组危化品') ||
                  isAdmin ||
                  isShowShenlingAndHeXiaoShenPin
                "
                >详情</el-button
              >
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 新增 -->
    <addOrUpadateVue
      :dialogVisible="dialogVisible"
      :dialogTitle="dialogTitle"
      :formData="formData"
      @submit="onSubmit"
      @close="closeDialog"
      @upload-success="uploadSuccess"
      @file-remove="handleImageRemove"
      @on-success="uploadOnSuccess"
    />

    <!-- 申领 -->
    <shenlingVue
      :dialogVisible="dialogShenling"
      :shenLingData="shenLingData"
      :isShowShenlingAndHeXiaoShenPin="isShowShenlingAndHeXiaoShenPin"
    ></shenlingVue>
    <!-- 研究组主任申领审核 -->
    <yjzshenlingVue :dialogVisible="dialogYjzShenlingShenhe"></yjzshenlingVue>
    <!-- 管理员申领审核 -->

    <glyshenlingVue :dialogVisible="dialogGlyShenlingShenhe"></glyshenlingVue>

    <!-- 核销页面 -->
    <hexiaoVue
      :dialogVisible="isShowHexiao"
      :hexiaoData="hexiaoData"
      :isShowShenlingAndHeXiaoShenPin="isShowShenlingAndHeXiaoShenPin"
    />
    <!-- 研究组核销审核 -->
    <yjzhexiaoVue
      :dialogVisible="dialogYjzHexiaoShenhe"
      @close="dialogYjzHexiaoShenhe = false"
    ></yjzhexiaoVue>

    <!-- 管理员核销审核 -->
    <glyhexiaoVue
      :dialogVisible="dialogGlyHexiaoShenhe"
      @close="dialogGlyHexiaoShenhe = false"
    ></glyhexiaoVue>
  </div>
</template>
<script>
import { transMD } from "@/utils/common";
import addOrUpadateVue from "./components/weihupin/addOrUpadate.vue";
//申领
import shenlingVue from "./components/weihupin/shenling.vue";
// 研究组主任 申领审核

import yjzshenlingVue from "./components/weihupin/yjzshenling.vue";
// 管理员 申领审核
import glyshenlingVue from "./components/weihupin/glyshenling.vue";

// 核销
import hexiaoVue from "./components/hexiao/hexiao.vue";
//研究组组长核销

import yjzhexiaoVue from "./components/hexiao/yjzhexiao.vue";

//管理员核销

import glyhexiaoVue from "./components/hexiao/glyhexiao.vue";

export default {
  components: {
    addOrUpadateVue,
    shenlingVue,
    yjzshenlingVue,
    glyshenlingVue,
    hexiaoVue,
    yjzhexiaoVue,
    glyhexiaoVue,
  },
  data() {
    return {
      yaopintype: "全部危化品",
      dialogVisible: false,
      dialogTitle: "新增危化品",

      searchQuery: "",
      items: [
        // 继续添加其他项目
      ],
      filteredItems: [],
      current_index: 1,
      isSHowShenlingAndHeXiao: false, //申领按钮  和 核销按钮 只有研究员才有权限
      isShowRuKuData: false, //入库按钮 管理人员
      isShowShenlingAndHeXiaoShenPin: false, //申领审批 核销审批 管理人员和研究组 主任
      formData: {
        name: "", // 药品名称
        cas: "", // CAS号
        controlled: "", // 是否管制内药品 (可以是'是'或'否')
        attribute: [], // 药品属性 (数组，用于多选)
        purchase: "", // 采购时间 (日期时间)
        sales: "", // 销售公司
        manufacturer: "", // 厂家
        spec: "", // 规格 (可以是单选值)
        quantity: null, // 数量 (数字)
        units: "瓶", // 单位
        shelf_life: "", // 保质期
        park: "", // 所在园区
        room: "", // 存放位置
        manage1_userid: null, // 主管理员1 (数字)
        manage2_userid: null, // 副管理员2 (数字)
        manage1_user_id: null, // 主管理员1 (数字)
        manage2_user_id: null, // 副管理员2 (数字)
        stock_in_time: "", // 入库时间 (日期时间)
        stock_in: null, // 入库量 (数字)
        stock: null, // 库存量 (数字)
        remark: "", // 备注
        create_by: "",
        create_time: null,
      },
      update_id: 0,

      //申领
      dialogShenling: false,
      //研究组主任申领审核
      dialogYjzShenlingShenhe: false,
      //管理员申领审核
      dialogGlyShenlingShenhe: false,

      shenLingData: {},

      //管理员 申领审核
      dialogGlyShenlingShenhe: false,

      // 核销
      isShowHexiao: false,
      hexiaoData: {},
      dialogYjzHexiaoShenhe: false,
      dialogGlyHexiaoShenhe: false,
      isAnquanyuan: false,
      uploadImgUrls: [],
      isMyAdmin: false,
    };
  },
  async created() {
    //this.filteredItems = await this.getList();
  },
  watch: {
    async jubJuse() {
      // select
    },
    async searchQuery(newQuery) {
      if (newQuery.length == 0) {
        this.current_index = 1;
        this.filteredItems = await this.getList();
        return;
      }
      this.searchItems();
    },
  },
  computed: {
    isAdmin() {
      let weihuapin_cerrent_roleid = localStorage.getItem(
        "weihuapin_cerrent_roleid"
      );

      if (weihuapin_cerrent_roleid) {
        if (parseInt(weihuapin_cerrent_roleid) < 4) {
          return true;
        } else {
          return false;
        }
      } else {
        let weihuapin_cerrent_roleids = localStorage.getItem(
          "weihuapin_cerrent_roleids"
        );
        if (weihuapin_cerrent_roleids) {
          weihuapin_cerrent_roleids = JSON.parse(
            weihuapin_cerrent_roleids
          ).whp_roles;
        }

        if (
          [1, 2, 3].some((role) =>
            (weihuapin_cerrent_roleids || []).includes(role)
          )
        ) {
          return true;
        }
      }
    },
    isEditAndDeleteEnabled() {
      if (this.yaopintype == "全部危化品") {
        if (this.isShowRuKuData) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.isShowShenlingAndHeXiaoShenPin) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  methods: {
    isGetAdmin() {
      let weihuapin_cerrent_roleid = localStorage.getItem(
        "weihuapin_cerrent_roleid"
      );

      if (weihuapin_cerrent_roleid) {
        if (parseInt(weihuapin_cerrent_roleid) < 4) {
          this.isMyAdmin = true;
          return true;
        } else {
          return false;
        }
      } else {
        let weihuapin_cerrent_roleids = localStorage.getItem(
          "weihuapin_cerrent_roleids"
        );
        if (weihuapin_cerrent_roleids) {
          weihuapin_cerrent_roleids = JSON.parse(
            weihuapin_cerrent_roleids
          ).whp_roles;
        }

        if (
          [1, 2, 3].some((role) => weihuapin_cerrent_roleids.includes(role))
        ) {
          this.isMyAdmin = true;
          return true;
        }
      }
    },

    hexiaoHazard(row) {
      this.hexiaoData = row;
      this.isShowHexiao = true;
    },
    closeDialogHexiao() {
      this.isShowHexiao = false;
    },

    async chooseYaopin() {
      if (this.yaopintype == "全部危化品") {
        this.filteredItems = await this.getList();
      } else {
        console.log("yanjiuzu");
        this.filteredItems = await this.getListYjz();
      }
    },
    //申领
    closeDialogShenling() {
      this.dialogShenling = false;
      this.dialogGlyShenlingShenhe = false;
      this.dialogYjzShenlingShenhe = false;
    },

    reset() {
      this.formData = {
        name: "", // 药品名称
        cas: "", // CAS号
        controlled: "", // 是否管制内药品 (可以是'是'或'否')
        attribute: [], // 药品属性 (数组，用于多选)
        purchase: "", // 采购时间 (日期时间)
        sales: "", // 销售公司
        manufacturer: "", // 厂家
        spec: "", // 规格 (可以是单选值)
        quantity: null, // 数量 (数字)
        units: "瓶", // 单位
        shelf_life: "", // 保质期
        park: "", // 所在园区
        room: "", // 存放位置
        factory_time: "",
        manage1_userid: null, // 主管理员1 (数字)
        manage2_userid: null, // 副管理员2 (数字)
        manage1_user_id: null, // 主管理员1 (数字)
        manage2_user_id: null, // 副管理员2 (数字)
        stock_in_time: "", // 入库时间 (日期时间)
        stock_in: null, // 入库量 (数字)
        stock: null, // 库存量 (数字)
        remark: "", // 备注
        create_by: "", // 创建人
        create_time: null, // 创建时间
      };
    },
    editHazard(item) {
      this.update_id = item.id;
      this.reset();
      for (let key in this.formData) {
        if (item.hasOwnProperty(key)) {
          this.formData[key] = item[key];
        }
      }

      this.formData.attribute = this.formData.attribute
        ? this.formData.attribute.split(",")
        : "";

      this.dialogTitle = "编辑危化品";
      this.dialogVisible = true;
    },
    deleteHazard(item) {
      // 删除药品代码
      // Confirm deletion
      this.$confirm("确定要删除该危化品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // Call the API to delete the item
          const deleteRes = await this.$db_delete(
            this.$http,
            "xtbg_hazard_info",
            "id=" + item.id
          );

          if (deleteRes.affectedRows > 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.filteredItems = await this.getList();
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleImageRemove(file, fileList) {
      console.log(file);
      console.log(fileList);
    },
    uploadSuccess(data) {
      //console.log(data);

      this.uploadImgUrls.push(data.url);
    },
    uploadOnSuccess(data) {
      //console.log(data);
    },
    async onSubmit(formData) {
      formData.purchase = this.$FormatDateToYMDHMS(formData.purchase, false);
      formData.factory_time = this.$FormatDateToYMDHMS(
        formData.factory_time,
        false
      );

      formData.stock_in_time = this.$FormatDateToYMDHMS(
        formData.stock_in_time,
        true
      );

      let unites = ["桶", "瓶", "袋", "钢瓶", "钢瓶"];
      formData.units =
        unites.find((item) => formData.spec.indexOf(item) > -1) || "瓶";

      //如果是研究组组 负责人 SELECT role_id FROM xtbg_hazard_role_users WHERE  userid='u97j84E18' AND role_id=4
      const wfpZzRes = await this.$db_select(
        this.$http,
        "xtbg_hazard_role_users",
        "count(*) as total",
        `userid='${localStorage.getItem("userid2")}' and role_id=4`
      );
      if (wfpZzRes[0].total > 0) {
        formData.group_userid = localStorage.getItem("userid");
        formData.group_user_id = localStorage.getItem("userid2");
        formData.guser_aptime = this.$FormatDateToYMDHMS(new Date(), true);
        formData.guser_result = "通过";
        formData.guser_remark = "同意通过";
      }

      if (this.dialogTitle == "新增危化品") {
        //新增内容提交
        formData.create_by = localStorage.getItem("userid");
        formData.create_userid = localStorage.getItem("userid2");
        formData.create_time = this.$FormatDateToYMDHMS(new Date(), true);

        formData.qrcode = await this.$generateQRCodeBase64(
          JSON.stringify(formData)
        );
        // console.log(formData);

        //return false;

        const insertRes = await this.$db_insert(
          this.$http,
          "xtbg_hazard_info",
          formData
        );
        // console.log(insertRes);
        if (insertRes.affectedRows > 0) {
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          //插入对应的图片到表 xtbg_hazard_info_photo
          if (this.uploadImgUrls.length > 0) {
            let photoData = {
              info_id: insertRes.insertId,
              photo_url: this.uploadImgUrls.join(","),
              userid: localStorage.getItem("usereid2"),
              type: 1,
              create_time: this.$FormatDateToYMDHMS(new Date(), true),
            };
            const photoInsertRes = await this.$db_insert(
              this.$http,
              "xtbg_hazard_info_photo",
              photoData
            );
            //console.log(photoInsertRes);
          }

          this.filteredItems = await this.getList();
        } else {
          this.$message({
            type: "error",
            message: "添加失败!",
          });
        }
      } else {
        //修改 内容提交

        if ("create_by" in formData) {
          delete formData.create_by;
        }
        if ("create_time" in this.formData) {
          delete formData.create_time;
        }

        this.formData.update_by = localStorage.getItem("userid");
        this.formData.update_userid = localStorage.getItem("userid2");
        this.formData.update_time = this.$FormatDateToYMDHMS(new Date(), true);

        formData.qrcode = await this.$generateQRCodeBase64(
          JSON.stringify(formData)
        );
        const updateRes = await this.$db_update(
          this.$http,
          "xtbg_hazard_info",
          formData,
          "id=" + this.update_id
        );
        if (this.uploadImgUrls.length > 0) {
          let photoData = {
            info_id: this.update_id,
            photo_url: this.uploadImgUrls.join(","),
            userid: localStorage.getItem("usereid2"),
            type: 1,
            create_time: this.$FormatDateToYMDHMS(new Date(), true),
          };

          const hasRes = await this.$db_select(
            this.$http,
            "xtbg_hazard_info_photo",
            "count(*) as total",
            "info_id=" + this.update_id
          );

          if (hasRes && hasRes[0].total > 0) {
            delete photoData.info_id;
            const updatePhotoRes = await this.$db_update(
              this.$http,
              "xtbg_hazard_info_photo",
              photoData,
              "info_id=" + this.update_id
            );
          } else {
            const photoInsertRes = await this.$db_insert(
              this.$http,
              "xtbg_hazard_info_photo",
              photoData
            );
          }
          this.$message({
            type: "success",
            message: "修改成功!",
          });

          //console.log(photoInsertRes);
        }

        //console.log(updateRes);

        if (insertRes.affectedRows > 0) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });

          this.filteredItems = await this.getList();
        } else {
          this.$message({
            type: "error",
            message: "修改成功!",
          });
        }
      }
    },
    //入库
    addHazard() {
      this.reset();
      this.dialogTitle = "新增危化品";
      this.dialogVisible = true;
    },
    //申领
    startApplication() {
      // Handle start application
      this.dialogShenling = true;

      //
    },
    //查看 申领审批记录
    viewApplications() {
      // Handle view applications
      //
      //

      let weihuapin_cerrent_roleid = localStorage.getItem(
        "weihuapin_cerrent_roleid"
      );
      //console.log(weihuapin_cerrent_roleid);

      let weihuapin_cerrent_roleids = localStorage.getItem(
        "weihuapin_cerrent_roleids"
      );
      if (weihuapin_cerrent_roleids) {
        weihuapin_cerrent_roleids = JSON.parse(
          weihuapin_cerrent_roleids
        ).whp_roles;
      }

      if (weihuapin_cerrent_roleid) {
        if (
          parseInt(weihuapin_cerrent_roleid) == 4 ||
          (weihuapin_cerrent_roleids || []).includes(4)
        ) {
          //研究组 主任
          this.dialogYjzShenlingShenhe = true;
        }
        if (
          parseInt(weihuapin_cerrent_roleid) == 2 ||
          parseInt(weihuapin_cerrent_roleid) == 3
        ) {
          // 管理员

          this.dialogGlyShenlingShenhe = true;
        }
      } else {
        if ((weihuapin_cerrent_roleids || []).includes(4)) {
          this.dialogYjzShenlingShenhe = true;
        }
        if (
          [2, 3].some((role) =>
            (weihuapin_cerrent_roleids || []).includes(role)
          )
        ) {
          // 管理员
          this.dialogGlyShenlingShenhe = true;
        }
      }
    },

    //核销
    startHexiao() {
      this.isShowHexiao = true;
    },
    viewHexiaoShenpin() {
      console.log("987987987");
      let weihuapin_cerrent_roleid = localStorage.getItem(
        "weihuapin_cerrent_roleid"
      );

      let weihuapin_cerrent_roleids = localStorage.getItem(
        "weihuapin_cerrent_roleids"
      );

      if (weihuapin_cerrent_roleids) {
        weihuapin_cerrent_roleids = JSON.parse(
          weihuapin_cerrent_roleids
        ).whp_roles;
      }

      if (weihuapin_cerrent_roleid) {
        if (
          parseInt(weihuapin_cerrent_roleid) == 4 ||
          (weihuapin_cerrent_roleids || []).includes(4)
        ) {
          //研究组 主任

          this.dialogYjzHexiaoShenhe = true;
        }
        if (weihuapin_cerrent_roleid == 2 || weihuapin_cerrent_roleid == 3) {
          // 管理员
          this.dialogGlyHexiaoShenhe = true;
        }
      } else {
        if ((weihuapin_cerrent_roleids || []).includes(4)) {
          this.dialogYjzHexiaoShenhe = true;
        }
        if ([2, 3].some((role) => weihuapin_cerrent_roleids.includes(role))) {
          // 管理员
          this.dialogGlyHexiaoShenhe = true;
        }
      }
    },
    closeDialog() {
      this.dialogVisible = false;
    },

    /**
     * 申领审核和核销审核 按 只对 库管管理员 和 审批管理员 和研究组 主任 开放
     */
    async showShenlingAndHeXiaoShenPin() {
      return await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_role_users",
        "user_id=" +
          localStorage.getItem("userid") +
          " and role_id>1 and role_id <=4"
      );
    },
    /**
     * 入库按钮 只对超级管理员和库管管理员 和 管理员开放
     */
    async showShowRuKuData() {
      return await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_role_users",
        "user_id=" +
          localStorage.getItem("userid") +
          " and role_id <4 and role_id >1"
      );
    },
    /**
     * 申领按钮 和 核销按钮 只有研究员才有权限
     */
    async showShenLingOrHeXiao() {
      const yjzBool = await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_studies",
        "userid=" + localStorage.getItem("userid")
      );
      const aqyBool = await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_role_users",
        "userid='" + localStorage.getItem("userid2") + "' and role_id=5"
      );
      return yjzBool && aqyBool;
    },
    // 安全员显示
    async showAnquanyuan() {
      const aqyBool = await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_role_users",
        "userid='" + localStorage.getItem("userid2") + "' and role_id=5"
      );
      return aqyBool;
    },

    async getList() {
      this.items = [];
      const tableName = "xtbg_hazard_info";
      let where =
        "1=1 order by id desc limit " + (this.current_index - 1) * 20 + ",20";

      const hazardRes = await this.$db_select(
        this.$http,
        tableName,
        "*",
        where
      );
      //console.log(hazardRes);
      return hazardRes;
    },
    async getListYjz() {
      this.items = [];
      const tableName = "xtbg_hazard_info_group";
      let where =
        "status=0 order by id desc limit " +
        (this.current_index - 1) * 20 +
        ",20";
      if (!this.isAdmin) {
        //如果研究组和研究员 只能查看本研究组的数据
        where =
          "status=0 and groupid=(select groupid from xtbg_hazard_studies where user_id='" +
          localStorage.getItem("userid2") +
          "' limit 1) order by id desc limit " +
          (this.current_index - 1) * 20 +
          ",20";
      }
      //console.log("kaishi")
      const hazardRes = await this.$db_select(
        this.$http,
        tableName,
        "*",
        where
      );
      console.log("jieshu");
      //console.log(hazardRes);
      return hazardRes;
    },
    async getYjzYaoPins() {
      this.items = [];
      const tableName = "xtbg_hazard_info_group";
      let where = "";
      //如果研究组和研究员 只能查看本研究组的数据
      where =
        "groupid=(select groupid from xtbg_hazard_studies where userid=" +
        localStorage.getItem("userid") +
        " limit 1) order by id desc limit " +
        (this.current_index - 1) * 20 +
        ",20";

      const hazardRes = await this.$db_select(
        this.$http,
        tableName,
        "*",
        where
      );
      console.log("jieshu");
      //console.log(hazardRes);
      return hazardRes;
    },
    querySearch(queryString, cb) {
      var restaurants = this.items.map((item) => {
        return {
          value: item.name,
        };
      });
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      this.searchQuery = item.value;
      this.searchItems();
    },
    async searchItems() {
      // const query = this.searchQuery.toLowerCase();
      // this.filteredItems = this.items.filter((item) =>
      //   item.name.toLowerCase().includes(this.searchQuery)
      // );
      const tableName = "xtbg_hazard_info";
      let where = "name like '%" + this.searchQuery + "%' order by id desc";

      const hazardRes = await this.$db_select(
        this.$http,
        tableName,
        "*",
        where
      );
      //console.log(hazardRes);
      this.filteredItems = hazardRes;
    },

    applyForItem(item) {
      // Handle apply for item
      console.log(`申请物品: ${item.name}`);

      this.dialogShenling = true;
      this.shenLingData = item;
    },

    viewDetails(item) {
      // Handle view item details
      //console.log(`查看详情: ${item.name}`);
      if (this.yaopintype == "全部危化品") {
        this.$router.push({
          path: "/weihuapin/detail",
          query: {
            id: item.id,
          },
        });
      } else {
        this.$router.push({
          path: "/weihuapin/yjzdetail",
          query: {
            id: item.id,
          },
        });
      }
    },

    async handleScroll(event) {
      if (document.getElementById("list_hazard")) {
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const pageBottom =
          document.getElementById("list_hazard").offsetTop +
          document.getElementById("list_hazard").offsetHeight;
        const viewportBottom = scrollTop + window.innerHeight;

        if (viewportBottom >= pageBottom - 10) {
          this.current_index = this.current_index + 1;

          let results = [];

          if (this.yaopintype == "全部危化品") {
            results = await this.getList();
          } else {
            results = await this.getListYjz();
          }

          if (results.length > 0) {
            this.filteredItems.push(...results);
            this.filteredItems = this.$_.uniqBy(this.filteredItems, "id");
          } else {
            this.current_index = 1;
          }

          //console.log(this.current_index);
        }
      }
    },
  },
  async mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    this.isSHowShenlingAndHeXiao = await this.showShenLingOrHeXiao(); //用于显示本研究药品
    this.isAnquanyuan = await this.showAnquanyuan();
    this.isShowRuKuData = await this.showShowRuKuData();
    this.isShowShenlingAndHeXiaoShenPin =
      await this.showShenlingAndHeXiaoShenPin();
    // console.log(this.yaopintype);
    // console.log(this.isAnquanyuan);
    // console.log(this.isAdmin);
    // this.isAdmin = this.isGetAdmin();

    if (this.isAdmin) {
      this.yaopintype = "全部危化品";
    } else if (this.isAnquanyuan) {
      this.yaopintype = "全部危化品";
    } else {
      this.yaopintype = "研究组危化品";
    }

    setTimeout(async () => {
      await this.chooseYaopin();
    }, 1000);

    window.addEventListener("wheel", this.handleScroll, {});
  },
  beforeDestroy() {
    window.removeEventListener("wheel", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 20px;
  text-align: left;
  font-size: 14px;
  background: #f8f8f8;
}
.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.search-input {
  flex-grow: 1;
  margin-right: 20px;
}
.item-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
}
.active {
  background: linear-gradient(to right, white 0%, rgb(236, 139, 139) 100%);
}
.item {
  /* background: #fafafa; */

  cursor: pointer;
  border-radius: 6px !important;
  border: 1px solid #daddeb;
  flex: 0 0 calc(25% - 20px); /* Ensure each item takes up 25% of the row, minus the gap */
  display: flex;
  flex-direction: column;
  box-shadow: 2px 2px 6px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
  }
  p {
    padding: 0px !important;
    margin: 0px !important;

    align-items: center;
    &:first-child {
      min-height: 40px;
      margin-bottom: 10px !important;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);

      /* // background: #f3f4f9;
      // border-bottom-left-radius: 10px;
      // border-bottom-right-radius: 10px; */
    }
    &:nth-child(n + 2) {
      height: 24px;
    }
  }

  .item-info p {
    padding: 0px 5px;
  }
}
.box-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
button {
  span {
    display: flex;
  }
}
.box_img {
  width: 20px;
  height: auto;
  padding: 10px 2px;
}
.item-photo {
  width: 100%;
  height: auto;
}
.item-info {
  /* padding: 10px 0; */
}
.item-actions {
  display: flex;

  padding-bottom: 5px;
  padding-right: 5px;
  padding-top: 5px;
}
</style>
