<!-- TableDialog.vue -->
<template>
  <div style="text-align: left">
    <el-dialog
      @close="$parent.closeUser"
      :visible.sync="visible"
      title="研究组用户列表"
      top="15vh"
      :close-on-click-modal="false"
    >
      <div>
        <el-button type="text" @click="openAddUserDialog" icon="el-icon-plus"
          >添加用户</el-button
        >
      </div>
      <el-table
        :data="currentPageData"
        style="width: 100%"
        width="100%"
        height="500"
      >
        <el-table-column prop="uid" label="序号" width="80"></el-table-column>
        <el-table-column prop="groupname" label="研究组"></el-table-column>
        <el-table-column prop="username" label="用户"></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              style="color: #409eff"
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="tableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage"
        @current-change="handlePageChange"
        style="margin-top: 10px"
      ></el-pagination>
    </el-dialog>

    <!-- 用户列表 -->
    <select-user
      :visible.sync="listUserVisible"
      multiple
      :select-rows-value="selectUserIds"
      @select="onSelectUser"
    ></select-user>
  </div>
</template>

<script>
import selectUser from "./select-user.vue";
export default {
  components: {
    selectUser,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
    groupid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      addUserDialogVisible: false,
      selectedRole: {},
      newUser: {
        userid: "",
      },
      listUserVisible: false,
      userAllTableData: [],
    };
  },
  async created() {},
  watch: {
    groupid(val) {
      if (val) {
        this.getGroupList();
      }
    },
  },

  computed: {
    currentPageData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.tableData.slice(start, end);
    },
    selectUserIds() {
      return this.userAllTableData.map((item) => item.id);
    },
  },
  methods: {
    async deleteUser(row) {
      const res = await this.$dbquery(
        this.$http,
        "DELETE FROM xtbg_hazard_studies WHERE id=" + row.id
      );
      //console.log(res);
      if (res.affectedRows > 0) {
        this.$message({
          showClose: true,
          message: "删除成功",
          type: "success",
          offset: 200,
        });
        this.getGroupList();
      } else {
        this.$message({
          showClose: true,
          message: "删除失败",
          type: "error",
          offset: 200,
        });
      }
    },
    async getGroupList() {
      const data = await this.$dbquery(
        this.$http,
        `SELECT st.*,(SELECT name FROM xtbg_hazard_study_group WHERE id=st.groupid LIMIT 1) as groupname,` +
          `(SELECT name FROM xtbg_user WHERE id=st.userid) as username FROM xtbg_hazard_studies st WHERE groupid=` +
          this.groupid
      );
      //console.log(data);
      let index = 0;
      this.tableData = data.map((row) => {
        index++;
        return {
          ...row,
          uid: index,
        };
      });
      this.total = data.length; // 设置数据总数
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    openAddUserDialog(role) {
      this.selectedRole = role;
      this.newUser.uesrid = "";
      this.listUserVisible = true;
    },
    addUser() {
      this.listUserVisible = true;
    },
    async getRoleAllUser() {
      const param =
        `SELECT *  ${this.userQueryWrapper} AND id IN (SELECT userid FROM ` +
        congress_role_user +
        ` WHERE groupid = '${this.selectRole.id}')`;

      const res = await this.$dbquery(this.$http, param);
      this.userAllTableData = res || [];
    },
    async onSelectUser(rows, cacelrows) {
      
      const groupid = 1;
      //插入到数据库 然后刷新

      //console.log(this.groupid);

      const promises = rows.map(async (row) => {
        // const sres = await this.$dbquery(
        //   this.$http,
        //   "select * from xtbg_hazard_studies where userid=" + row.id
        // );
        // console.log(sres);
        // if (sres.length == 0) {

        // const insertSql =
        //   "insert into xtbg_hazard_studies(userid,user_id,groupid) values(" +
        //   row.id +
        //   "," +",'"+row.userid+"',"
        //   this.groupid +
        //   ")";
        const insRes = await this.$db_insert(
          this.$http,
          "xtbg_hazard_studies",
          {
            userid: row.id,
            user_id: row.userid,
            groupid: this.groupid,
          }
        );
        //console.log(insRes);
        return insRes;
        // }
      });
      Promise.all(promises).then((res) => {
        this.getGroupList();
      });
    },
  },
};
</script>
