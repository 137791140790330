/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-12-03 23:09:13
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-05 09:33:35
 * @FilePath: \app_wb\src\utils\qrcpde.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// utils/qrcodeGenerator.js
import QRCode from "qrcode";

/**
 * Generates a QR code and returns it as a base64-encoded string.
 * @param {string} text - The text to encode in the QR code.
 * @param {Object} [customOptions] - Optional custom configuration.
 * @param {number} [customOptions.width=200] - Width and height of QR code in pixels.
 * @param {string} [customOptions.darkColor='#000000'] - Color of QR code modules (dark).
 * @param {string} [customOptions.lightColor='#ffffff'] - Color of QR code background (light).
 * @param {number} [customOptions.margin=4] - Margin around QR code (in modules).
 * @param {string} [customOptions.errorCorrectionLevel='M'] - Error correction level (L, M, Q, H).
 * @returns {Promise<string>} - A promise that resolves to a base64-encoded string of the QR code image.
 */
export async function generateQRCodeBase64(text, customOptions = {}) {
  try {
    // Default options
    const defaultOptions = {
      width: 200,
      darkColor: "#000000",
      lightColor: "#ffffff",
      margin: 4,
      errorCorrectionLevel: "M",
    };

    // Merge default options with custom options
    const options = {
      ...defaultOptions,
      ...customOptions,
      // QRCode specific options
      color: {
        dark: customOptions.darkColor || defaultOptions.darkColor,
        light: customOptions.lightColor || defaultOptions.lightColor,
      },
    };

    // Create a new canvas element
    const canvas = document.createElement("canvas");

    // Generate QR code on canvas
    await QRCode.toCanvas(canvas, text, {
      width: options.width,
      margin: options.margin,
      color: options.color,
      errorCorrectionLevel: options.errorCorrectionLevel,
    });

    // Convert canvas to base64-encoded string
    const result = canvas.toDataURL("image/png").split(",")[1];

    // console.log(result);

    return "data:image/png;base64," + result;
  } catch (error) {
    console.error("Error generating QR code:", error);
    throw error;
  }
}
