<template>
  <el-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    id="dialog_shenling"
    :show-close="true"
    :fullscreen="true"
    @close="close"
  >
    <div slot="title" class="dialog-title">
      <el-row>
        <el-button type="text" @click="close" icon="el-icon-arrow-left"
          >返回</el-button
        >
        <span class="dialog-title-text" style="padding-left: 10px"
          >危化品核销审批</span
        >
      </el-row>
    </div>
    <!-- 审批记录 -->
    <div class="sl_records">
      <h3 class="section-title">审批记录</h3>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        border
        height="82vh"
        :default-sort="{ prop: 'uid', order: 'ascending' }"
      >
        <el-table-column
          prop="uid"
          label="序号"
          sortable
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="核销药品"></el-table-column>
        <el-table-column prop="spec" label="规格"></el-table-column>
        <el-table-column prop="weight" label="核销量"></el-table-column>
        <el-table-column prop="waste_type" label="废物类别"></el-table-column>
        <el-table-column prop="reason" label="核销原因"></el-table-column>
        <el-table-column
          prop="main_hazard"
          label="主要危害成分"
        ></el-table-column>

        <el-table-column
          prop="create_username"
          label="核销人"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          sortable
          label="核销时间"
        ></el-table-column>

        <el-table-column prop="status" sortable label="核销状态">
          <template slot-scope="scope">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <el-steps
                  direction="vertical"
                  :active="getActiveStep(scope.row)"
                >
                  <el-step
                    :title="scope.row.create_username"
                    description="
                      提交核销
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.group_user_id
                        ? scope.row.group_username
                        : '研究组负责人'
                    "
                    :description="
                      scope.row.group_user_id
                        ? scope.row.guser_result +
                          ' - ' +
                          scope.row.guser_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.admin_user_id
                        ? scope.row.admin_username
                        : '管理员'
                    "
                    :description="
                      scope.row.admin_user_id
                        ? scope.row.admin_result +
                          ' - ' +
                          scope.row.admin_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.admin_result)"
                  ></el-step>
                </el-steps>
              </div>
              <span>
                <span
                  style="color: #f00"
                  v-if="scope.row.status === 0 || scope.row.status === null"
                  >待审核</span
                >
                <span style="color: #085" v-if="scope.row.status === 1"
                  >已审核</span
                >
                <span style="color: #f00" v-if="scope.row.status === 2"
                  >已核销</span
                >

                <span style="color: #085" v-if="scope.row.status === 4"
                  >已退回</span
                >
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="yjzzzShenHe(scope.row)"
              type="text"
              icon="el-icon-view"
              size="small"
              v-if="
                scope.row.group_user_id == null || scope.row.group_user_id == ''
              "
              >审核</el-button
            >

            <!-- <el-button type="text" size="small" style="color:#085" v-else>已审核</el-button> -->
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        layout="total,prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>

    <!-- 审核页面 -->
    <el-drawer
      :visible="yjzShDialog"
      id="yjzShDialog"
      :modal="false"
      @close="yjzShDialoghandleClose"
    >
      <div>
        <el-form style="padding: 0px 20px">
          <el-row>
            <h3 class="section-title">核销信息</h3>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="药品:">
                <span
                  style="
                    color: #000;
                    text-decoration: underline;
                    cursor: pointer;
                    color: #085;
                  "
                  @click="showYaopin"
                  >{{ HexiaoForm.info_name }}</span
                >
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="核销量:">
                <span style="color: #000">{{ HexiaoForm.weight }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="规格:">
                <span style="color: #000">{{ HexiaoForm.info_spec }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="废物类别:">
                <span style="color: #000">{{ HexiaoForm.waste_type }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="核销原因:">
                <span style="color: #000">{{ HexiaoForm.reason }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="主要危害成分:">
                <span style="color: #000">{{ HexiaoForm.main_hazard }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="核销说明:">
                <span style="color: #000">{{ HexiaoForm.remark }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="核销人:">
                <span style="color: #000">{{
                  HexiaoForm.create_username
                }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="核销时间:">
                <span style="color: #000">{{ HexiaoForm.create_time }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="审核意见">
                <el-input
                  style="color: #000; font-size: 15px"
                  type="textarea"
                  v-model="HexiaoForm.guser_remark"
                  placeholder="请输入审核意见"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div class="dialog-footer">
        <el-button
          type="default"
          @click="yjzShDialoghandleClose"
          icon="el-icon-close"
          >取消</el-button
        >
        <el-button type="success" @click="approveHexiao" icon="el-icon-check"
          >通过</el-button
        >
        <el-button type="danger" @click="rejectTuihui" icon="el-icon-back"
          >退回</el-button
        >
      </div>
    </el-drawer>
    <showdetailVue
      :showDetail="showDetail"
      @close="closeShowDetail"
    ></showdetailVue>
  </el-dialog>
</template>

<script>
import showdetailVue from "./showdetail.vue";

export default {
  props: {
    dialogVisible: Boolean,
    isShowShenlingAndHeXiaoShenPin: Boolean,
  },
  components: {
    showdetailVue,
  },
  data() {
    return {
      pageSize: 20,
      total: 0,
      curent_index: 1,
      loading: true,
      formData: {
        name: "",
        info_id: "",
        weight: "",
        purpose: "",
        create_by: null,
      },
      HexiaoForm: {
        id: null,
        info_id: "",
        info_name: "",
        info_spec: "",
        weight: "",

        create_by: null,
        create_time: "",
        create_username: "",
        remark: "",
        waste_type: "",
        reason: "",
        main_hazard: "",
      },
      yjzShDialog: false,
      current_stock: "",
      create_name: localStorage.getItem("username"),
      names: [],
      allnames: [],
      rules: {
        name: [{ required: true, message: "请选择药品", trigger: "blur" }],
        weight: [{ required: true, message: "请输入领用量", trigger: "blur" }],
        purpose: [
          { required: true, message: "请输入领用用途", trigger: "blur" },
        ],
        create_by: [
          { required: true, message: "请输入领用人", trigger: "blur" },
        ],
      },

      tableData: [],
      showDetail: false,
      show_id: null,
    };
  },
  async created() {
    // let shenheData = await this.$db_select(this.$http, "");
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getSlList();
      }
    },
  },
  methods: {
    closeShowDetail() {
      this.showDetail = false;
    },
    currentChange(index) {
      this.curent_index = index;
      this.getSlList();
    },
    yjzShDialoghandleClose() {
      this.yjzShDialog = false;
    },
    showYaopin() {
      this.show_id = this.HexiaoForm.info_id;

      this.showDetail = true;
    },
    closeShowYaopin() {
      this.showDetail = false;
    },
    async yjzzzShenHe(row) {
      //console.log(row);
      for (let key in row) {
        if (key in this.HexiaoForm) {
          this.HexiaoForm[key] = row[key];
        }
      }
      //药品信息
      const yp_info = await this.$db_select(
        this.$http,
        "xtbg_hazard_info_group",
        "name,spec",
        "id=" + row.info_id
      );
      this.HexiaoForm.info_name = yp_info[0].name;
      this.HexiaoForm.info_spec = yp_info[0].spec;
      let slr_info = "";
      if (row.create_by) {
        slr_info = await this.$db_select(
          this.$http,
          "xtbg_user",
          "name",
          "id=" + row.create_by
        );
      } else {
        slr_info = await this.$db_select(
          this.$http,
          "xtbg_user",
          "name",
          `userid='${row.create_userid}'`
        );
      }

      this.HexiaoForm.create_username = slr_info[0].name;

      this.yjzShDialog = true;
    },
    async approveHexiao() {
      this.HexiaoForm.result = "通过";

      let shForm = {
        group_userid: localStorage.getItem("userid"),
        group_user_id: localStorage.getItem("userid2"),
        guser_aptime: this.$FormatDateToYMDHMS(new Date(), true),
        guser_result: "通过",
        guser_remark: this.HexiaoForm.guser_remark,
      };
      const result = await this.$db_update(
        this.$http,
        "xtbg_hazard_writoff",
        shForm,
        "id=" + this.HexiaoForm.id
      );
      console.log(result);
      if (result.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "审核成功",
        });
        this.yjzShDialog = false;
      } else {
        this.$message({
          type: "error",
          message: "审核失败",
        });
        this.yjzShDialog = false;
      }
      this.getSlList();
    },
    async rejectTuihui() {
      let shForm = {
        group_userid: localStorage.getItem("userid"),
        group_user_id: localStorage.getItem("userid2"),
        guser_aptime: this.$FormatDateToYMDHMS(new Date(), true),
        guser_result: "未通过",
        guser_remark: this.HexiaoForm.guser_remark,
        status: 4,
      };
      const result = await this.$db_update(
        this.$http,
        "xtbg_hazard_writoff",
        shForm,
        "id=" + this.HexiaoForm.id
      );
      console.log(result);
      if (result.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "审核成功",
        });
        this.yjzShDialog = false;
      } else {
        this.$message({
          type: "error",
          message: "审核失败",
        });
        this.yjzShDialog = false;
      }
      this.getSlList();
    },

    getStepStatus(result) {
      if (result === "通过") return "success";
      if (result === "未通过") return "error";
      return "process";
    },
    getActiveStep(row) {
      if (row.admin_result === "通过" || row.admin_result === "未通过") {
        return 2;
      } else if (row.guser_result === "通过" || row.guser_result === "未通过") {
        return 1;
      } else {
        return 0;
      }
    },

    reset() {
      this.formData = {
        info_id: "",
        spec: "",
        weight: "",
        purpose: "",
        create_by: null,
        status: 0,
      };
    },
    querySearch(queryString, cb) {
      var restaurants = this.names;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      //this.formData.cas = item.cas;
      // 在这里处理选中的项目\
      console.log(item);

      this.formData.info_id = item.id;

      this.current_stock = item.stock;

      this.formData.spec = item.spec;
      this.$refs.formData.validateField("name");
    },
    handleMedicineSelect() {
      // 处理药品选择的逻辑
      console.log("选择药品");
    },
    close() {
      //this.$parent.closeDialogHexiao();
      this.$emit("close");
    },
    async getSlList() {
      //获取本组id

      // this.tableData = await Promise.all(
      //   res.map(async (row) => {
      //     row_index++;
      //     row.uid = row_index;
      //     if (row.group_userid) {
      //       row.group_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.group_userid
      //         )
      //       )[0].name;
      //       row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);
      //     }
      //     if (row.admin_userid) {
      //       row.admin_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.admin_userid
      //         )
      //       )[0].name;
      //       row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);
      //     }

      //     return {
      //       ...row,
      //       name: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_hazard_info",
      //           "name",
      //           "id=" + row.info_id
      //         )
      //       )[0].name,
      //       create_username: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.create_by
      //         )
      //       )[0].name,
      //       create_time: this.$FormatDateToYMDHMS(row.create_time, true),
      //     };
      //   })
      // );

      // this.loading = true;

      // const groupidRs = await this.$db_select(
      //   this.$http,
      //   "xtbg_hazard_studies",
      //   "groupid",
      //   "userid=" + localStorage.getItem("userid")
      // );

      // if (!groupidRs) {
      //   this.$message.error("获取本组id失败");
      //   return false;
      // }

      // const group_id = groupidRs[0].groupid;

      // const res = await this.$db_select(
      //   this.$http,
      //   "xtbg_hazard_writoff",
      //   "*",
      //   "group_id=" + group_id + " order by create_time DESC  limit " +
      //     (this.curent_index - 1) * this.pageSize +
      //     "," +
      //     this.pageSize
      // );
      // let row_index = 0;

      // this.tableData = await Promise.all(
      //   res.map(async (row) => {
      //     row_index++;
      //     row.uid = row_index;
      //     if (row.group_userid) {
      //       row.group_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.group_userid
      //         )
      //       )[0].name;
      //       row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);
      //     }
      //     if (row.admin_userid) {
      //       row.admin_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.admin_userid
      //         )
      //       )[0].name;
      //       row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);
      //     }

      //     return {
      //       ...row,
      //       name: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_hazard_info",
      //           "name",
      //           "id=" + row.info_id
      //         )
      //       )[0].name,
      //       create_username: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.create_by
      //         )
      //       )[0].name,
      //       create_time: this.$FormatDateToYMDHMS(row.create_time, true),
      //     };
      //   })
      // );
      // this.loading = false;
      // const totalRes = await this.$db_select(this.$http,"xtbg_hazard_writoff","count(*) as total","group_id=" + group_id);
      // this.total = totalRes[0].total;

      // this.loading = true;

      // try {
      //   const groupidRs = await this.$db_select(
      //     this.$http,
      //     "xtbg_hazard_studies",
      //     "groupid",
      //     "userid=" + localStorage.getItem("userid")
      //   );

      //   if (!groupidRs || groupidRs.length === 0) {
      //     this.$message.error("获取本组id失败");
      //     this.loading = false;
      //     return false;
      //   }

      //   const group_id = groupidRs[0].groupid;

      //   const res = await this.$db_select(
      //     this.$http,
      //     "xtbg_hazard_writoff",
      //     "*",
      //     "group_id=" +
      //       group_id +
      //       " order by create_time DESC  limit " +
      //       (this.curent_index - 1) * this.pageSize +
      //       "," +
      //       this.pageSize
      //   );

      //   if (!res || res.length === 0) {
      //     this.tableData = [];
      //     this.loading = false;
      //     return;
      //   }

      //   let row_index = 0;

      //   this.tableData = await Promise.all(
      //     res.map(async (row) => {
      //       row_index++;
      //       row.uid = row_index;

      //       const queries = [];

      //       if (row.group_userid) {
      //         queries.push(
      //           this.$db_select(
      //             this.$http,
      //             "xtbg_user",
      //             "name",
      //             "id=" + row.group_userid
      //           ).then((result) => {
      //             row.group_username = result[0]?.name || "";
      //             row.guser_aptime = this.$FormatDateToYMDHMS(
      //               row.guser_aptime,
      //               true
      //             );
      //           })
      //         );
      //       }

      //       if (row.admin_userid) {
      //         queries.push(
      //           this.$db_select(
      //             this.$http,
      //             "xtbg_user",
      //             "name",
      //             "id=" + row.admin_userid
      //           ).then((result) => {
      //             row.admin_username = result[0]?.name || "";
      //             row.admin_aptime = this.$FormatDateToYMDHMS(
      //               row.admin_aptime,
      //               true
      //             );
      //           })
      //         );
      //       }

      //       const [nameResult, createUsernameResult] = await Promise.all([
      //         this.$db_select(
      //           this.$http,
      //           "xtbg_hazard_info",
      //           "name",
      //           "id=" + row.info_id
      //         ),
      //         this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.create_by
      //         ),
      //       ]);

      //       row.name = nameResult[0]?.name || "";
      //       row.create_username = createUsernameResult[0]?.name || "";
      //       row.create_time = this.$FormatDateToYMDHMS(row.create_time, true);

      //       await Promise.all(queries);

      //       return row;
      //     })
      //   );

      //   const totalRes = await this.$db_select(
      //     this.$http,
      //     "xtbg_hazard_writoff",
      //     "count(*) as total",
      //     "group_id=" + group_id
      //   );
      //   this.total = totalRes[0]?.total || 0;
      // } catch (error) {
      //   console.error("加载数据时发生错误:", error);
      //   this.$message.error("加载数据时发生错误");
      // } finally {
      //   this.loading = false;
      // }
      this.loading = true;

      try {
        const groupidRs = await this.$db_select(
          this.$http,
          "xtbg_hazard_studies",
          "groupid",
          `user_id='${localStorage.getItem("userid2")}'`
        );

        //console.log(groupidRs);

        if (!groupidRs || groupidRs.length === 0) {
          this.$message.error("获取本组id失败");
          this.loading = false;
          return false;
        }

        const group_id = groupidRs[0].groupid;

        const res = await this.$db_select(
          this.$http,
          "xtbg_hazard_writoff",
          "*",
          "group_id=" +
            group_id +
            " order by create_time DESC limit " +
            (this.curent_index - 1) * this.pageSize +
            "," +
            this.pageSize
        );

        if (!res || res.length === 0) {
          this.tableData = [];
          this.loading = false;
          return;
        }

        // 提取所有可能的 group_userid、admin_userid、create_by 和 info_id
        const groupUserIds = [
          ...new Set(res.map((row) => row.group_user_id).filter(Boolean)),
        ];
        const adminUserIds = [
          ...new Set(res.map((row) => row.admin_user_id).filter(Boolean)),
        ];
        const createByIds = [
          ...new Set(res.map((row) => row.create_userid).filter(Boolean)),
        ];
        const infoIds = [
          ...new Set(res.map((row) => row.info_id).filter(Boolean)),
        ];
        // 合并 adminUserIds 和 createByIds，避免重复查询
        const userIds = [
          ...new Set([...groupUserIds, ...adminUserIds, ...createByIds]),
        ];
        const useridses = userIds.map((row) => `'${row}'`).join(",");

        // 批量查询用户信息和 hazard_info 信息
        const [users, infos] = await Promise.all([
          userIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_user",
                "id, name, userid",
                `userid IN (${useridses})`
              )
            : [],
          infoIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_hazard_info_group",
                "id, name",
                `id IN (${infoIds.join(",")})`
              )
            : [],
        ]);

        // 构建 ID 到名称的映射
        // 构建 ID 到名称的映射
        const userMap = Object.fromEntries(
          users.map((user) => [user.userid, user.name])
        );
        // console.log(userMap);
        const infoMap = Object.fromEntries(
          infos.map((info) => [info.id, info.name])
        );

        // 处理数据
        let row_index = 0;

        this.tableData = res.map((row) => {
          row_index++;
          row.uid = row_index;

          row.group_username = userMap[row.group_user_id] || "";
          row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);

          row.admin_username = userMap[row.admin_user_id] || "";
          row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);

          row.name = infoMap[row.info_id] || "";
          row.create_username = userMap[row.create_userid] || ""; // 使用 userMap 映射 create_by
          row.create_time = this.$FormatDateToYMDHMS(row.create_time, true);

          return row;
        });

        // 获取总数
        const totalRes = await this.$db_select(
          this.$http,
          "xtbg_hazard_writoff",
          "count(*) as total",
          "group_id=" + group_id
        );
        this.total = totalRes[0]?.total || 0;
      } catch (error) {
        console.error("加载数据时发生错误:", error);
        this.$message.error("加载数据时发生错误");
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteClick(row) {
      if (
        this.isShowShenlingAndHeXiaoShenPin &&
        row.admin_user_id != "" &&
        row.admin_user_id != null
      ) {
        console.log(44);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }
      // console.log(row);
      if (row.group_user_id != "" && row.group_user_id != null) {
        console.log(66);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      //删除
      const deleteRes = await this.$db_delete(
        this.$http,
        "xtbg_hazard_writoff",
        "id=" + row.id
      );
      if (deleteRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        await this.getSlList();
      } else {
        this.$message({
          type: "error",
          message: "删除失败",
        });
      }
    },
    handleSubmit() {
      // 处理表单提交逻辑
      //console.log("表单提交", this.formData);
      //this.dialogVisible = false;

      this.formData.create_by = localStorage.getItem("userid");
      if (this.formData.weight == 0) {
        this.$message({
          type: "warning",
          message: "领用量不能为0",
        });
        return false;
      }
      if (this.formData.weight > this.current_stock) {
        this.$message({
          type: "warning",
          message: "领用量不能大于库存量",
        });
        return false;
      }

      delete this.formData.name;

      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          //开始提交
          this.formData.create_time = this.$FormatDateToYMDHMS(
            new Date(),
            true
          );
          //console.log(this.formData);

          // 判断是否是负责人
          if (this.isShowShenlingAndHeXiaoShenPin) {
            this.formData.group_userid = localStorage.getItem("userid");
            this.formData.group_user_id = localStorage.getItem("userid2");
            this.formData.guser_result = "通过";
            this.formData.guser_remark = "通过";
            this.formData.guser_aptime = this.$FormatDateToYMDHMS(
              new Date(),
              true
            );
          }

          const insertRes = await this.$db_insert(
            this.$http,
            "xtbg_hazard_writoff",
            this.formData
          );
          //console.log(insertRes);
          if (insertRes.affectedRows > 0) {
            this.reset();
            this.$message({
              type: "success",
              message: "核销成功!",
            });
            this.getSlList();
          } else {
            this.$message({
              type: "error",
              message: "核销失败!",
            });
          }
        } else {
          console.log("表单验证失败");
        }
      });
    },
  },
  async mounted() {
    this.reset();
    this.allnames = await this.$db_select(
      this.$http,
      "xtbg_hazard_info_group",
      "*"
    );

    this.names = this.allnames.map((item) => {
      if (item.cas) {
        item.name = item.name + "(" + item.cas + ")";
        item.label = item.name + "(" + item.cas + ")";
      }

      return {
        id: item.id,
        value: item.name,
        label: item.label,
        cas: item.cas,
        stock: item.stock,
        spec: item.spec,
      };
    });

    //核销记录
  },
};
</script>

<style scoped>
.steps-tooltip {
  width: 300px;
  white-space: normal;
}
.section {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f7fa;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #67c23a;
  margin-bottom: 10px;
  border-left: 4px solid #67c23a;
  padding-left: 10px;
}

.medicine-info {
  display: flex;
  align-items: center;
}

.medicine-info span {
  flex-grow: 1;
}

.inventory-status {
  color: red;
  margin-left: 10px;
}

.dialog-footer {
  text-align: right;
  padding: 10px 0;
}

.sl_records {
  /* display: flex;
  flex-direction: column; */
}
</style>
