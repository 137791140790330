<template>
  <div>
    <el-dialog
      id="weihuapin_add"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%;"
      style="margin-top: -60px !important"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-form :model="formData" :rules="rules" label-width="100px" ref="form">
        <el-row>
          <div
            :span="24"
            style="
              height: 30px;
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            "
          >
            <div style="height: 30px; width: 5px; background: #8ec28d"></div>
            <div
              style="
                flex: 1;
                height: 30px;
                line-height: 30px;
                padding-left: 10px;
                background: #f4f8f4;
              "
            >
              药品信息
            </div>
          </div>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="14">
            <el-form-item label="药品名称" prop="name">
              <el-autocomplete
                v-model="formData.name"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
                style="width: 100%"
              ></el-autocomplete>
              <!-- <el-select
              v-model="formData.name"
              filterable
              clearable
              placeholder="搜索选择"
              style="width: 100%"
              @change="selectYapPin"
            >
              <el-option
                v-for="item in names"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="CAS号" prop="cas">
              <el-input v-model="formData.cas"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="管制内药品" prop="controlled">
              <el-radio
                v-for="(item, index) in yaopingguanzhi"
                :label="item.value"
                v-model="formData.controlled"
                :key="'ypgz' + index"
                >{{ item.value }}</el-radio
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="药品属性" prop="attribute">
              <el-checkbox
                v-for="(item, index) in yaopingshuxing"
                :label="item.value"
                v-model="formData.attribute"
                :key="'ypgz' + index"
                >{{ item.value }}</el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="规格" prop="spec">
              <el-radio
                v-for="(item, index) in guige"
                :label="item.value"
                v-model="formData.spec"
                :key="'ypgz' + index"
                >{{ item.value }}</el-radio
              >
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="单位">
            <el-radio-group v-model="formData.units">
              <el-radio label="规格1">单位1</el-radio>
              <el-radio label="规格2">单位2</el-radio>
              <el-radio label="规格3">单位3</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row> -->
        <el-row>
          <div
            :span="24"
            style="
              height: 30px;
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            "
          >
            <div style="height: 30px; width: 5px; background: #8ec28d"></div>
            <div
              style="
                flex: 1;
                height: 30px;
                line-height: 30px;
                padding-left: 10px;
                background: #f4f8f4;
              "
            >
              采购信息
            </div>
          </div>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="采购时间" prop="purchase">
              <el-date-picker
                v-model="formData.purchase"
                type="date"
                format="yyyy-MM-dd"
                style="width: 200px"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="销售公司" prop="sales">
              <el-input v-model="formData.sales"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="厂家">
              <el-input v-model="formData.manufacturer"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="数量" prop="quantity">
              <el-input-number v-model="formData.quantity"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出厂时间" prop="factory_time">
              <el-date-picker
                v-model="formData.factory_time"
                type="date"
                format="yyyy-MM-dd"
                style="width: 200px"
              ></el-date-picker>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="保质期" prop="shelf_life">
              <el-input type="Number" v-model="formData.shelf_life">
                <template slot="append">月</template>
              </el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
          <el-form-item label="库存量">
            <el-input-number
              v-model="formData.stock"
              :min="0"
              style="width: 140px"
            ></el-input-number>
          </el-form-item>
        </el-col> -->
        </el-row>
        <el-row>
          <div
            :span="24"
            style="
              height: 30px;
              width: 100%;
              display: flex;
              align-items: center;
              margin-bottom: 5px;
            "
          >
            <div style="height: 30px; width: 5px; background: #8ec28d"></div>
            <div
              style="
                flex: 1;
                height: 30px;
                line-height: 30px;
                padding-left: 10px;
                background: #f4f8f4;
              "
            >
              入库存储信息
            </div>
          </div>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="入库时间" prop="stock_in_time">
              <el-date-picker
                v-model="formData.stock_in_time"
                style="width: 200px"
                format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="入库量" prop="stock_in">
              <el-input-number
                v-model="formData.stock_in"
                :min="0"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="库存量">
              <el-input-number
                v-model="formData.stock"
                :min="0"
                disabled
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所在园区" prop="park">
              <el-input v-model="formData.park"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" style="display: flex">
            <el-form-item label="存放位置" prop="room">
              <el-input v-model="formData.room"></el-input>
            </el-form-item>
            <el-button
              type="default"
              icon="el-icon-plus"
              @click="uploadDialog = true"
              style="
                height: 40px;
                min-width: 40px;
                text-align: center;
                padding: 0px 8px;
                margin-left: 5px;
              "
              >上传图片</el-button
            >
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="主管理员1" prop="manage1_userid">
              <el-select
                v-model="formData.manage1_userid"
                filterable
                clearable
                placeholder="搜索选择"
                style="width: 100%"
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="副管理员2">
              <el-select
                v-model="formData.manage2_userid"
                filterable
                clearable
                style="width: 100%"
                placeholder="搜索选择"
              >
                <el-option
                  v-for="item in users"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="出库量">
            <el-input-number
              v-model="formData.stock_out"
              :min="0"
              style="width: 140px"
            ></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出库时间">
            <el-date-picker
              v-model="formData.stock_out_time"
              type="datetime"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row> -->

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="formData.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="$parent.closeDialog" icon="el-icon-close"
          >取消</el-button
        >
        <el-button type="primary" @click="handleSubmit" icon="el-icon-check"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="上传图片"
      :visible.async="uploadDialog"
      :close-on-click-modal="false"
      @close="uploadDialog = false"
    >
      <el-upload
        ref="imageUpload"
        :http-request="customUploadRequest"
        :action="''"
        :multiple="true"
        :limit="5"
        :on-exceed="handleExceed"
        :file-list="imageFileList"
        :on-preview="handlePreview"
        :on-remove="handleImageRemove"
        :on-success="onSuccess"
        list-type="picture-card"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogImageVisible">
        <img width="100%" :src="dialogImageUrl" alt="预览" />
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import yImageUploadVue from "@/components/commom/ImageUpload.vue";
export default {
  props: {
    dialogVisible: Boolean,
    dialogTitle: String,
    formData: Object,
  },
  components: {
    yImageUploadVue,
  },
  data() {
    return {
      uploadFileUrls: [],

      uploadData: {
        num: "whp_images",
      },
      label: "存储位置",
      maxFiles: 5,
      uploadUrl: "https://in.xtbg.ac.cn:8088/upload",
      uploadDialog: false,
      rules: {
        name: [
          { required: true, message: "药品名称不能为空", trigger: "blur" },
        ],
        cas: [{ required: true, message: "CAS号不能为空", trigger: "blur" }],
        spec: [{ required: true, message: "规格不能为空", trigger: "blur" }],
        controlled: [
          { required: true, message: "管制内药品必选", trigger: "blur" },
        ],
        attribute: [
          { required: true, message: "药品属性必选", trigger: "blur" },
        ],
        shelf_life: [
          { required: true, message: "保质期不能为空", trigger: "blur" },
        ],
        factory_time: [
          { required: true, message: "出厂时间不能为空", trigger: "blur" },
        ],
        purchase: [
          { required: true, message: "采购时间不能为空", trigger: "blur" },
        ],

        sales: [
          { required: true, message: "销售公司不能为空", trigger: "blur" },
        ],
        stock_in_time: [
          { required: true, message: "入库时间不能为空", trigger: "blur" },
        ],
        stock_in: [
          { required: true, message: "入库量不能为空", trigger: "blur" },
        ],
        park: [
          { required: true, message: "所在园区不能为空", trigger: "blur" },
        ],
        room: [
          { required: true, message: "存放位置不能为空", trigger: "blur" },
        ],
        quantity: [
          { required: true, message: "数量不能为空", trigger: "blur" },
        ],
        manage1_userid: [
          { required: true, message: "主管理员1不能为空", trigger: "blur" },
        ],
      },
      yaopingguanzhi: [],
      yaopingshuxing: [],
      guige: [],
      danwei: [],

      users: [],
      names: [],
      allnames: [],
      dialogImageUrl:'',
      dialogImageVisible:false,
      imageFileList:[]
    };
  },
  watch: {
    "formData.quantity": {
      handler: function (val) {
        this.formData.stock_in = val;
      },
    },
    "formData.stock_in": {
      handler: function (val) {
        this.formData.stock = val;
      },
    },
  },
  methods: {
    handleExceed(files, fileList) {
      this.$message.warning(
        `上传文件数量超出限制，最多只能上传 ${this.maxFiles} 张`
      );
    },
    handlePreview(file) {
      console.log(file);
      this.dialogImageUrl = file.url;
      this.dialogImageVisible = true;
    },
    handleImageRemove(file, fileList) {
      this.imageFileList = fileList;
      this.$emit("file-remove", file, fileList);
    },
    onSuccess(response, file, fileList) {
      this.$emit("on-success", {
        response,
        file,
        fileList,
      });
    },
    async customUploadRequest({ file }) {
      if (!this.uploadData.num) {
        this.$message({
          type: "error",
          message: "请先输入仪器编号",
        });
        return;
      }

      const form = new FormData();

      form.append("file", file);

      // 从父组件传入的外部数据追加到表单中
      form.append("num", this.uploadData.num);

      this.uploadFileUrls = [];
      this.$http
        .post(this.uploadUrl, form)
        .then((response) => {
          // console.log("上传成功");
          // console.log(response);
          this.$emit("upload-success", response.data);
          // this.uploadFileUrls.push(response.data.url);
        })
        .catch((error) => {
          //this.$emit("upload-error", error);
        });

      // 将上传成功的响应数据传递回父组件

      // 清空文件列表
      //this.imageFileList = [];
    },

    querySearch(queryString, cb) {
      var restaurants = this.names;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      this.formData.cas = item.cas;
      // 在这里处理选中的项目
    },
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("submit", this.formData);
          this.$emit("close");
        } else {
          console.log("表单验证失败");
        }
      });
    },
    selectYapPin(row) {
      console.log(row);
    },
  },
  async mounted() {
    this.yaopingguanzhi = await this.$YAOPINGUANGZHI(this.$http);
    this.yaopingshuxing = await this.$YAOPINSHUXING(this.$http);
    this.guige = await this.$GUIGE(this.$http);
    this.danwei = await this.$DANWEI(this.$http);
    this.users = await this.$db_select(this.$http, "xtbg_user", "id,name");
    this.users = this.users.map((user) => {
      return {
        value: user.id,
        label: user.name,
      };
    });
    this.allnames = await this.$db_select(
      this.$http,
      "xtbg_hazard_basic",
      "name,alias,cas"
    );

    this.names = this.allnames.map((item) => {
      return {
        value: item.name,
        label: item.name + "(" + item.cas + ")",
        cas: item.cas,
      };
    });
  },
};
</script>

<style scoped>
.dialog-footer {
  text-align: right;
}
</style>
