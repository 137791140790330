<template>
  <el-drawer
    :visible="showDetail"
    id="detail_dialog"
    :before-close="handleClose"
    :with-header="false"
    :modal="false"
    :size="'50%'"
  >
    <div style="text-align: left; padding: 20px">
      <el-row :gutter="20" class="weihuapin_detail">
        <!-- 左上角 - 操作按钮 -->

        <!-- 图片展示 -->
        <el-col :span="12" class="image-container">
          <el-image
            style="width: auto; height: 250px"
            :src="images[0] || noimg"
            fit="fit"
            :preview-src-list="images"
          ></el-image>
        </el-col>

        <!-- 药品信息 -->
        <el-col :span="12">
          <el-card class="info-card">
            <div slot="header" class="clearfix">
              <span>药品信息</span>
            </div>
            <div>
              <p><strong>药品名称</strong> {{ record.name }}</p>
              <p><strong>CAS号</strong> {{ record.cas }}</p>
              <p><strong>是否管制内药品</strong> {{ record.controlled }}</p>
              <p><strong>药品属性</strong> {{ record.attribute }}</p>
              <p><strong>规格</strong> {{ record.spec }}</p>
            </div>
          </el-card>
        </el-col>

        <!-- 采购信息 -->
        <el-col :span="8">
          <el-card class="info-card">
            <div slot="header" class="clearfix">
              <el-icon><i class="el-icon-shopping-cart-full"></i></el-icon>
              <span>采购信息</span>
            </div>
            <div>
              <p><strong>采购时间</strong> {{ record.purchase }}</p>
              <p><strong>销售公司</strong> {{ record.sales }}</p>
              <p><strong>厂家</strong> {{ record.manufacturer }}</p>
              <p><strong>数量</strong> {{ record.quantity }}</p>
              <p><strong>保质期</strong> {{ record.shelf_life }}</p>
            </div>
          </el-card>
        </el-col>

        <!-- 存放信息 -->
        <el-col :span="8">
          <el-card class="info-card">
            <div slot="header" class="clearfix">
              <el-icon><i class="el-icon-house"></i></el-icon>
              <span>存放信息</span>
            </div>
            <div>
              <p><strong>所在园区</strong> {{ record.park }}</p>
              <p v-if="record.study_groupid">
                <strong>所在研究组</strong> {{ record.study_groupid }}
              </p>
              <p>
                <strong v-if="record.room">存放位置</strong> {{ record.room }}
              </p>
              <p><strong>库存量</strong> {{ record.stock }}</p>

              <p><strong>主管理员</strong> {{ record.manage1_userid }}</p>
              <p>
                <strong v-if="record.manage2_userid">副管理员</strong>
                {{ record.manage2_userid }}
              </p>
            </div>
          </el-card>
        </el-col>

        <!-- 右下角二维码 -->
        <el-col span="8">
          <el-card class="info-card qr-card">
            <div slot="header" class="clearfix" style="text-align: left">
              <span>详情二维码</span>
            </div>
            <div class="qr-code">
              <el-image
                style="width: 100%; height: auto"
                :src="record.qrcode || nocode"
                fit="fit"
              ></el-image>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <el-row>
        <el-col>
          <el-button
            type="text"
            icon="el-icon-arrow-left"
            style="float: left; margin-left: 20px"
            @click="handleClose"
            >返回</el-button
          >
        </el-col>
      </el-row>
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    showDetail: {
      type: Boolean,
      default: false,
    },
    show_id: Number,
  },

  data() {
    return {
      isShowShenlingAndHeXiaoShenPin: false,
      shenLingData: {},
      dialogShenling: false,

      isSHowShenlingAndHeXiao: false,
      noimg: require("@/assets/imgs/weihuapin/noimg.png"),
      nocode: require("@/assets/imgs/weihuapin/nocode.png"),

      record: {},
      images: [],
    };
  },
  async created() {
    this.isSHowShenlingAndHeXiao = await this.showShenLingOrHeXiao();

    this.isShowShenlingAndHeXiaoShenPin =
      await this.showShenlingAndHeXiaoShenPin();
  },
  watch: {
    async show_id(id) {
      if (id) {
        let data = await this.$db_select(
          this.$http,
          "xtbg_hazard_info_group",
          "*",
          "id=" + id
        );
        this.record = data[0];

        const imgRes = await this.$db_select(
          this.$http,
          "xtbg_hazard_info_group_photo",
          "photo_url",
          "type=1 and infogroup_writoff_id=" +  this.record.id
        );
        this.images =
          imgRes.length > 0 ? imgRes.map((item) => item.photo_url) : [];

        this.$nextTick(async () => {
          if (this.record) {
            this.record.purchase = this.$FormatDateToYMDHMS(new Date(), true);
            const user1Res = await this.$db_select(
              this.$http,
              "xtbg_user",
              "name",
              "id=" + this.record.manage1_userid
            );
            if (user1Res.length > 0) {
              this.record.manage1_userid = user1Res[0].name;
            }
            const user2Res = await this.$db_select(
              this.$http,
              "xtbg_user",
              "name",
              "id= " + this.record.manage2_userid
            );
            if (user2Res.length > 0) {
              this.record.manage2_userid = user2Res[0].name;
            }
          }
        });
      }
    },
  },
  methods: {
    handleClose() {
      console.log("关闭");
      this.$emit("close");
      // this.$parent.closeShowDetail();
    },

    async showShenLingOrHeXiao() {
      return await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_studies",
        "userid=" + localStorage.getItem("userid")
      );
    },

    async showShenlingAndHeXiaoShenPin() {
      return await this.$judgeTrueOrFalse(
        this.$http,
        "xtbg_hazard_role_users",
        "user_id=" +
          localStorage.getItem("userid") +
          " and role_id>1 and role_id <=4"
      );
    },
    closeDialogShenling() {
      this.dialogShenling = false;
      this.dialogGlyShenlingShenhe = false;
      this.dialogYjzShenlingShenhe = false;
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
}
strong {
  font-weight: normal;
  color: rgb(166, 166, 166);
  display: block;
  min-width: 70px;
}
/* 自定义样式 */
.operation-buttons {
  text-align: right;
  margin-bottom: 20px;
}

.image-container {
  text-align: center;
}

.info-card {
  margin-bottom: 20px;
}

.qr-card {
  text-align: center;
}

.qr-code {
  padding: 20px;
}
</style>
