<template>
  <div class="container">
    <div class="header" style="width: 60%">
      <el-autocomplete
        v-model="searchQuery"
        :fetch-suggestions="querySearch"
        placeholder="危废名称"
        prefix-icon="el-icon-search"
        @select="handleSelect"
        :trigger-on-focus="false"
        class="search-input"
      />

      <el-checkbox
        style="margin-left: 20px"
        @change="checkAll"
        v-model="checkedAll"
        >全选</el-checkbox
      >

      <button class="cart-button">
        <img
          src="../../assets/imgs/weihuapin/出库车图标.png"
          class="button-icon"
          alt="出库车图标"
        />
        <el-badge :value="ck_total" style="margin-top: 0px; margin-right: 10px"
          ><span style="height: 30px; padding-right: 7px"
            >出库车</span
          ></el-badge
        >
      </button>

      <button class="export-button" @click="exportCkWaste">
        <img
          src="../../assets/imgs/weihuapin/出库图标.png"
          class="button-icon"
          alt="出库图标"
        />
        开始出库
      </button>
    </div>
    <div class="items">
      <div
        class="item"
        v-for="item in filteredItems"
        :key="item.id"
       
      >
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/危废名称图标.png"
            class="icon"
            alt="危废名称图标"
          /> -->
          <span
            style="
              display: block;
              text-align: left;
              color: #a6a6a6;
              width: 60px;
              padding-right: 2px;
            "
            >危废名称</span
          >
          <span style="width: calc(100% - 85px)">
            {{ item.waste_name
            }}<span style="font-size: 12px"
              >({{ item.waste_type }} {{ item.waste_code }}}})</span
            >
          </span>
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/主要危害成分图标.png"
            class="icon"
            alt="主要危害成分图标"
          /> -->
          <span
            style="
              display: block;
              text-align: left;
              color: #a6a6a6;
              width: 60px;
              padding-right: 2px;
            "
          >
            危害成分</span
          >

          <span style="width: calc(100% - 85px)">
            {{ item.main_hazard }}
          </span>
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/产生研究组图标.png"
            class="icon"
            alt="产生研究组图标"
          /> -->
          <span
            style="
              display: block;
              text-align: left;
              color: #a6a6a6;
              width: 60px;
              padding-right: 2px;
            "
          >
            产生途径</span
          >
          <span style="width: calc(100% - 85px)">
            {{ item.production }}
          </span>
        </p>
        <p>
          <span
            style="
              display: block;
              text-align: left;
              color: #a6a6a6;
              width: 60px;
              padding-right: 2px;
            "
          >
            规格</span
          >
          <span style="width: calc(100% - 85px)"> {{ item.spec }}</span>
        </p>
        <p>
          <span
            style="
              display: block;
              text-align: left;
              color: #a6a6a6;
              width: 60px;
              padding-right: 2px;
            "
          >
            产生重量</span
          >
          <span style="width: calc(100% - 85px)"> {{ item.weight }}</span>
        </p>
        <p>
          <!-- <img
            src="../../assets/imgs/weihuapin/产生时间图标.png"
            class="icon"
            alt="产生时间图标"
          /> -->
          <span
            style="
              display: block;
              text-align: left;
              color: #a6a6a6;
              width: 60px;
              padding-right: 2px;
            "
          >
            产生时间
          </span>
          <span style="width: calc(100% - 85px)">
            {{ item.create_time }}
          </span>
        </p>
        <div style="float: right;height: 30px; padding-right: 10px;display: flex;height: 30px; align-items: center;">
          <el-button type="text" style="margin-left: auto; margin-right: 20px;"  @click="showWasteDetail(item)">详情</el-button>

          <el-checkbox
            @change="changeChcked"
           
            v-model="item.checked"
            >出库</el-checkbox
          >
        </div>
      </div>
    </div>
    <el-drawer
      title="危废品详情"
      :visible.sync="drawerVisible"
      size="20%"
      style="text-align: left;"
      :close-on-click-modal="false"
    >
      <div v-if="selectedWasteItem" style="text-align: left; ">
        <p style="background: #f2f2f2; height: 30px; line-height: 30px;padding: 0px 20px;">基础信息</p>
        <p style="padding: 0px 20px;">
          <strong>危废品名称:</strong> {{ selectedWasteItem.waste_name }} ({{
            selectedWasteItem.waste_type
          }})
        </p>
        <p style="padding: 0px 20px;"><strong>废物类别:</strong> {{ selectedWasteItem.waste_type }}</p>
        <p style="padding: 0px 20px;"><strong>废物代码:</strong> {{ selectedWasteItem.waste_code }}</p>
        <p style="padding: 0px 20px;"><strong>重量:</strong> {{ selectedWasteItem.weight }}</p>
        <p style="padding: 0px 20px;"><strong>规格:</strong> {{ selectedWasteItem.spec }}</p>
        
        <p style="padding: 0px 20px;">
          <strong>主要危害成分:</strong> {{ selectedWasteItem.main_hazard }}
        </p>
        
        <p style="padding: 0px 20px;"><strong>所在园区:</strong> {{ selectedWasteItem.park }}</p>
        <p style="padding: 0px 20px;"><strong>暂存房间:</strong> {{ selectedWasteItem.storage_room }}</p>
        <p style="padding: 0px 20px;"><strong>备注:</strong> {{ selectedWasteItem.remark }}</p>
        <p style="background: #f2f2f2; height: 30px; line-height: 30px;padding: 0px 20px;">危废来源</p>
        <p style="padding: 0px 20px;"><strong>产生途径:</strong> {{ selectedWasteItem.production }}</p>
        <p style="padding: 0px 20px;"><strong>产生时间:</strong> {{ selectedWasteItem.create_time }}</p>
        <p style="padding: 0px 20px;"><strong>产生研究组:</strong> {{ selectedWasteItem.study_groupname }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="drawerVisible = false">关闭</el-button>
      </span>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchQuery: "",
      filteredItems: [],
      items: [],
      current_index: 1,
      ck_total: 0,
      ck_items: [],
      checkedAll: false,
      selectedWasteItem: null, // To hold the selected waste item details
      drawerVisible: false, // To control the visibility of the drawer
    };
  },
  async created() {
    this.items = await this.getListWfp();
    this.filteredItems = this.items.map((item) => {
      return {
        ...item,
        checked: false,
        create_time: this.$FormatDateToYMDHMS(item.create_time, true),
      };
    });
  },
  watch: {
    searchQuery(newQuery) {
      if (newQuery.length == 0) {
        this.filteredItems = this.items;
        return;
      }
      this.searchItems();
    },
  },
  methods: {
    showWasteDetail(item) {
      this.selectedWasteItem = item; // Set the selected item
      this.drawerVisible = true; // Open the drawe
    },
    async exportCkWaste() {
      if (this.ck_items.length === 0) {
        this.$message.warning("请先选择要导出的危废品");
        return;
      }

      // Convert data to CSV format
      const headers = [
        "危废名称",
        "危害成分",
        "产生途径",
        "规格",
        "产生重量",
        "产生时间",
      ];
      const keys = [
        "waste_name",
        "main_hazard",
        "production",
        "spec",
        "weight",
        "create_time",
      ];

      let csvContent = headers.join(",") + "\n";

      this.ck_items.forEach((item) => {
        const row = keys.map((key) => {
          // Wrap values in quotes and escape existing quotes
          const value = item[key] || "";
          return `"${value.toString().replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create and download the file
      const blob = new Blob(["\ufeff" + csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `危废品出库清单_${new Date().toLocaleDateString()}.csv`
      );
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      await Promise.all(
        this.ck_items.map(async (row) => {
          await this.$db_update(
            this.$http,
            "xtbg_hazard_waste",
            { status: 1 },
            "id=" + row.id
          );
        })
      );

      this.ck_total = 0;

      this.items = await this.getListWfp();
      this.filteredItems = this.items.map((item) => {
        return {
          ...item,
          checked: false,
          create_time: this.$FormatDateToYMDHMS(item.create_time, true),
        };
      });
    },
    checkAll(value) {
      if (value) {
        this.filteredItems = this.filteredItems.map((item) => {
          item.checked = true;
          return item;
        });
      } else {
        this.filteredItems = this.filteredItems.map((item) => {
          item.checked = false;
          return item;
        });
      }
      this.ck_items = this.filteredItems.filter((row) => {
        return row.checked === true;
      });
      this.ck_total = this.ck_items.length;
      // console.log(this.ck_items);
    },
    changeChcked(value) {
      this.ck_items = this.filteredItems.filter((row) => {
        return row.checked === true;
      });
      this.ck_total = this.ck_items.length;
      //
    },
    async getListWfp() {
      this.items = [];
      const tableName = "xtbg_hazard_waste";
      let where =
        "status=0 order by id desc limit " +
        (this.current_index - 1) * 20 +
        ",20";

      const hazardRes = await this.$db_select(
        this.$http,
        tableName,
        `xtbg_hazard_waste.*,
        (select name from xtbg_hazard_study_group where id=xtbg_hazard_waste.study_groupid) as study_groupname`,
        where
      );
      // console.log(hazardRes);
      return hazardRes;
    },
    querySearch(queryString, cb) {
      var restaurants = this.items.map((item) => {
        return {
          value: item.name,
        };
      });
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      this.searchQuery = item.value;
      this.searchItems();
    },
    searchItems() {
      // const query = this.searchQuery.toLowerCase();
      this.filteredItems = this.items.filter((item) =>
        item.name.toLowerCase().includes(this.searchQuery)
      );
    },
  },
  mounted() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
};
</script>
<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  font-size: 14px;
  background: #f8f8f8;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  flex-grow: 1;
}

.search-bar {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
}
.cart-button {
  margin-left: 10px;
}
.cart-button,
.export-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #fafafa;
  color: #008500;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cart-button:hover,
.export-button:hover {
  background-color: #e7f1eb;
}

.items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.item {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee0e6;
  border-radius: 8px;
  background: #fff;

  box-shadow: 2px 2px 6px 4px rgba(0, 0, 0, 0.1);

  width: calc(25% - 8px); /* 根据需要调整这个值 */
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  }
}

.item p {
  padding: 0px !important;
  margin: 0 !important;
  min-height: 30px;
  text-align: left;
  display: flex;

  &:first-child {
    background: #ffffff;
    height: 40px;
    margin-bottom: 10px !important;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:nth-child(n + 2) {
    height: 30px;
  }
  padding-left: 10px !important;

  display: flex;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.button-icon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.export-item-button {
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #fafafa;
  color: rgb(34, 34, 34);
  border: none;
  border-radius: 4px;
  max-width: 80px;
  cursor: pointer;
  margin: 0px 5px 5px auto;
}

.export-item-button:hover {
  background-color: #e7f1eb;
}
</style>
