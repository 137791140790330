/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 10:27:54
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-06 16:24:37
 * @FilePath: \app_wb\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import MetaInfo from "vue-meta-info";
import fullCalendar from "vue-fullcalendar";
import vuetimeline from "@growthbunker/vuetimeline";
import VueResource from "vue-resource";

// 提案系统 权限控制配置文件
import congresCofig from "../config/config.js";

import { dbquery } from "./views/proposal/sys/proposeRoleUser";
import { generateQRCodeBase64 } from "./utils/qrcpde.js";
import _ from "lodash";

Vue.use(VueResource);

// Vue.http.interceptors.push((request,next)=>{
//   console.log("request made with ",request);
//   next((response)=>{
//     if(response.status===401){

//       console.log("unauthorized")

//     }
//   })
// })
Vue.use(vuetimeline);
Vue.component("full-calendar", fullCalendar);

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(MetaInfo);
Vue.prototype.$_ = _;
//const ht_api_url = "http://in.xtbg.ac.cn:8181";
const ht_api_url = "https://in.xtbg.ac.cn:8443";

const db_dynamic_url = "https://in.xtbg.ac.cn:8574/dynamic";
const news_url = "https://www.xtbg.cas.cn/tjzy/mneaw";

Vue.prototype.$api_url = ht_api_url;
Vue.prototype.$db_dynamic_url = db_dynamic_url;
Vue.prototype.$news_url = news_url;

Vue.prototype.$congresConfig = congresCofig;

Vue.prototype.$generateQRCodeBase64 = generateQRCodeBase64;

const myDbquery = async (myhttp, sqlStr) => {
  return await dbquery(myhttp, sqlStr);
};

//重构 数据crud
const db_select = async (myhttp, tableName, sds = "*", where = "1=1") => {
  const selectSql = "select " + sds + " from " + tableName + " where " + where;

  //console.log(selectSql);

  return await myDbquery(myhttp, selectSql);
};
const db_insert = async (myhttp, tableName, formData) => {
  const fds = Object.keys(formData).join(",");
  const values = Object.values(formData)
    .map((value) => {
      if (typeof value == "number") {
        return value;
      } else {
        if (value == null) {
          return `${value}`;
        } else {
          if (value == "null") {
            return null;
          } else {
            if (value == "undefined") {
              return null;
            } else {
              return `'${value}'`;
            }
          }
        }
      }
    })
    .join(",");
  const insertSql =
    "insert into " + tableName + "(" + fds + ") values(" + values + ")";

  //console.log(insertSql);

  return await myDbquery(myhttp, insertSql);
};
const db_update = async (myhttp, tableName, formData, where) => {
  // const update_Data = Object.keys(formData)
  //   .map((key) => {
  //     const value = formData[key];
  //     console.log(value);
  //     if (typeof value == "number") {
  //       return `${key}=${value}`;
  //     } else {
  //       if (value == "null") {
  //         return undefined;
  //       } else {
  //         if (value == "undefined") {
  //           return undefined;
  //         } else {
  //           return `${key}='${value}'`;
  //         }
  //       }

  //       //return `${key}='${value}'`;
  //     }
  //   }).filter(Boolean)
  //   .join(",");
  const update_Data = Object.keys(formData)
    .map((key) => {
      const value = formData[key];
      if (typeof value === "number") {
        return `${key}=${value}`;
      } else if (value !== null && value !== "undefined" && value !== "null") {
        return `${key}='${value}'`;
      }
      return undefined;
    })
    .filter(Boolean)
    .join(",");
  const updateSql =
    "update " + tableName + " set " + update_Data + " where " + where;
  // console.log(updateSql);
  return await myDbquery(myhttp, updateSql);
};
const db_delete = async (myhttp, tableName, where) => {
  const deleteSql = "delete from " + tableName + " where " + where;
  return await myDbquery(myhttp, deleteSql);
};
Vue.config.productionTip = true;

Vue.prototype.$dbquery = myDbquery;
Vue.prototype.$db_select = db_select;
Vue.prototype.$db_insert = db_insert;
Vue.prototype.$db_update = db_update;
Vue.prototype.$db_delete = db_delete;
const toSnakeCase = (obj) => {
  const newObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Convert the key to snake_case
      const snakeKey = key.replace(/([A-Z])/g, "_$1").toLowerCase();

      // Assign the value to the new key in the new object
      newObj[snakeKey] = obj[key];
    }
  }

  return newObj;
};

Vue.prototype.$getDeptNameByDeptId = async (myhttp, id) => {
  return await db_select(myhttp, "sys_dept", "dept_name", "dept_id=" + id);
};

const toSnakeUpper = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase()),
      value,
    ])
  );
};

Vue.prototype.$ToSnakeCase = toSnakeCase;
Vue.prototype.$ToSnakeUpper = toSnakeUpper;
const formatDateToYMDHMS = (dateString, bool = false) => {
  const date = new Date(dateString);

  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // 月份从 0 开始，需要加 1
  const dd = String(date.getDate()).padStart(2, "0");
  const HH = String(date.getHours()).padStart(2, "0");
  const MM = String(date.getMinutes()).padStart(2, "0");
  const SS = String(date.getSeconds()).padStart(2, "0");
  if (bool) {
    return `${yyyy}-${mm}-${dd} ${HH}:${MM}:${SS}`;
  } else {
    return `${yyyy}-${mm}-${dd}`;
  }
};
Vue.prototype.$FormatDateToYMDHMS = formatDateToYMDHMS;

const judgeTrueOrFalse = async (myhttp, tableName, where) => {
  const data = await db_select(myhttp, tableName, "id", where);
  return data.length > 0;
};

Vue.prototype.$judgeTrueOrFalse = judgeTrueOrFalse;

//字典 xtbg_hazard_dict
// 是否管制内药品
const dict_guanzhinei = async (myHttp) => {
  return await db_select(
    myHttp,
    "xtbg_hazard_dict",
    "name,value",
    "type='是否管制内药品'"
  );
};

Vue.prototype.$YAOPINGUANGZHI = dict_guanzhinei;
// 药品属性
const dict_attributes = async (myHttp) => {
  return await db_select(
    myHttp,
    "xtbg_hazard_dict",
    "name,value",
    "type='药品属性'"
  );
};
Vue.prototype.$YAOPINSHUXING = dict_attributes;

// 有害成分
const dict_youhaichengfen = async (myHttp) => {
  return await db_select(
    myHttp,
    "xtbg_hazard_dict",
    "name,value",
    "type='有害成分'"
  );
};
Vue.prototype.$YOUHAICHENGFEN = dict_attributes;
// 规格
const dict_guige = async (myHttp) => {
  return await db_select(
    myHttp,
    "xtbg_hazard_dict",
    "name,value",
    "type='规格'"
  );
};
Vue.prototype.$GUIGE = dict_guige;
const dict_jiliangdanwei = async (myHttp) => {
  return await db_select(
    myHttp,
    "xtbg_hazard_dict",
    "name,value",
    "type='计量单位'"
  );
};
Vue.prototype.$DANWEI = dict_jiliangdanwei;

function postMSG(phoneNumber, templateId) {
  let smsData = {
    phoneNumber: phoneNumber,
    templateId: templateId,
  };
  return new Promise((resolve) => {
    this.$http
      .post("https://in.xtbg.ac.cn:3102/send-msg", smsData)
      .then((res) => {
        resolve(res);
      });
  });
}
Vue.prototype.$SendMSG = postMSG;

//所有部门
const deptList = async (myHttp) => {
  return await db_select(myHttp, "sys_dept", "dept_id,dept_name");
};
Vue.prototype.$deptList = deptList;

const getUserPhone = async (myHttp, userid) => {
  return await db_select(myHttp, "xtbg_user", "phone", "id=" + userid);
};
Vue.prototype.$GetUserPhone = getUserPhone;
const getCdGlyUserPhones = async (myHttp) => {
  return await db_select(
    myHttp,
    "xtbg_user",
    "phone",
    "id in (SELECT user_id FROM xtbg_officecar_role_user WHERE role_id=1)"
  );
};
Vue.prototype.$GetCdGlyUserPhones = getCdGlyUserPhones;
//所有用户
const userList = async (myHttp) => {
  return await db_select(myHttp, "xtbg_user", "id,name,email,phone");
};
Vue.prototype.$userList = userList;

// 所有职位

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
