<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-03-28 10:46:10
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-03 14:16:53
 * @FilePath: \app_wb\src\components\Header.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="bny_header">
    <div class="bny_header_top">
      <!-- <ul id="top_header_ul">
        <li
          v-for="(item, index) in top_data_left"
          :key="index"
          @click="clickTopItem($event,item)"
        >
          {{ item.name }}


        </li>
      </ul> -->
      <el-row>
        <el-col :span="12">
          <el-row>
            <el-col
              :span="24 / top_data_left.length"
              style="
                text-align: center;
                font-size: 12px;
                line-height: 40px;
                padding-left: 10px;
                cursor: pointer;
                width: 100px;
              "
              v-for="(item, index) in top_data_left"
              :key="index"
              @click.native="clickTopItem($event, item)"
            >
              {{ item.name }}
            </el-col>
            <el-col :span="16"></el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row>
            <el-col :span="15" style="height: 40px"></el-col>
            <el-col
              :span="3"
              @click.native="clickTopItem($event, top_data_right_two)"
              style="
                text-align: center;
                cursor: pointer;
                font-size: 12px;
                line-height: 40px;
                width: 100px;
              "
              >{{ top_data_right_two.name }}</el-col
            >

            <el-col
              :span="4"
              style="
                text-align: right;
                font-size: 12px;
                line-height: 40px;
                cursor: pointer;
                min-width: 100px;
              "
              >[当前用户:<span
                style="color: #519f6d"
                @click="gotoPersonal(username)"
                >{{ username }}</span
              >]</el-col
            >
            <el-col
              id="btn_login_status"
              :span="1"
              @click.native="loginOut()"
              style="
                text-align: center;
                cursor: pointer;
                font-size: 12px;
                line-height: 40px;
                padding-left: 10px;
                width: 40px;
              "
              >{{ top_data_right_one.name }}</el-col
            >
          </el-row>
        </el-col>
      </el-row>
    </div>
    <div class="bny_header_nav">
      <a href="/">
        <img :src="header_logo" alt="" />
      </a>
      <div class="wb_name">{{ wb_name }}</div>
      <div class="bny_header_nav_items">
        <div class="bny_showtime">
          <ShowTimer></ShowTimer>
        </div>
        <div class="bny_navs">
          <ul>
            <li
              v-for="(item, index) in nav_items"
              :key="index"
              @click="clickNavItem(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShowTimer from "../components/commom/shotime.vue";
import { ListWebsiteInfo } from "@/api/content/xtbg_website_info.js";
import { ListWebsiteLinks } from "@/api/content/xtbg_website_links.js";
import { ListWebsiteMenu } from "@/api/content/xtbg_website_menu.js";
var _this = null;
export default {
  components: {
    ShowTimer,
  },
  data() {
    return {
      header_logo: "",
      wb_name: "",
      showtime: "",
      top_data_left: [],
      top_data_right_one: "",
      top_data_right_two: "",
      nav_items: [],
      queryPramas: {
        pageSize: 10,
        pageNum: 1,
      },
      username: null,
      wjhidden: true,
    };
  },
  watch: {
    "$store.state.loginwj": {
      handler(newV) {
        //console.log(newV);
        this.getWebsitMenu();
      },
      deep: true,
    },
    "$store.state.loginStatus": {
      handler(newV, oldV) {
        if (newV || oldV) {
          this.username = localStorage.getItem("username");

          let btn_login_status = document.getElementById("btn_login_status");
          if (btn_login_status.innerText.indexOf("登录") > -1) {
            btn_login_status.innerText = "退出";
          }
        }
      },
      deep: true,
    },
  },
  created() {
    _this = this;
    this.getWebsiteInfo();
    this.getYqLinks();
    this.getWebsitMenu();
  },
  methods: {
    //个人中心
    gotoPersonal(username) {
      this.$router.push({
        path: "/personal",
        query: {
          username: username,
        },
      });
    },
    loginOut() {
      this.$confirm("确定要退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {

          this.lo
          this.$store.commit("setLoginStatus", false);
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    clickTopItem1(url) {
      if (url.indexOf("http" > -1)) {
        window.open(url);
      } else {
        this.$router
          .push({
            path: url,
          })
          .catch((err) => {});
      }
    },
    clickTopItem(e, item) {
      let url = item.url;

      if (item.name.indexOf("登录") > -1) {
        this.$store.commit("setLoginStatus", false);
        this.$router
          .push({
            path: url,
          })
          .catch((err) => {});
      }
      if (item.name.indexOf("退出") > -1) {
      } else {
        if (url.indexOf("http" > -1)) {
          window.open(url);
        } else {
          this.$router
            .push({
              path: url,
            })
            .catch((err) => {});
        }
      }
    },
    clickNavItem(item) {
      this.$router
        .push({
          path: item.url,
          query: {
            name: item.name,
          },
        })
        .catch((err) => {});
    },
    getWebsitMenu() {
      let roleName = localStorage.getItem("roleName");
      //判断权限，如果是外籍 用户 则隐藏有些模块
      if (roleName != null) {
        if (roleName.indexOf("外籍") > -1) {
          this.wjhidden = false;
        } else {
          this.wjhidden = true;
        }
      }

      ListWebsiteMenu(this.queryPramas).then((res) => {
        let rows = res.data.rows;
        rows = rows.sort(this.up);

        let roleName = localStorage.getItem("roleName");
        //判断权限，如果是外籍 用户 则隐藏有些模块
        if (roleName != null) {
          if (roleName.indexOf("外籍") > -1) {
            rows = rows.filter((row) => {
              return row.name.indexOf("通讯") == -1;
            });
            rows = rows.filter((row) => {
              return row.name.indexOf("内网公告") == -1;
            });
          }
        }

        this.nav_items = rows;
      });
    },
    getYqLinks() {
      this.yqoptions = [];
      this.queryPramas.location = "网站顶部";
      ListWebsiteLinks(this.queryPramas).then((res) => {
        let rows = res.data.rows;
        rows = rows.sort(this.up2);
        this.top_data_left = [];
        this.top_data_right = [];
        this.top_data_right_one = rows.filter((row) => {
          return row.name == "登录";
        })[0];
        this.top_data_right_two = rows.filter((row) => {
          return row.name == "后台管理";
        })[0];
        this.top_data_left = rows
          .filter((row) => {
            return row.name != "登录";
          })
          .filter((row) => {
            return row.name != "后台管理";
          });
        // for (let i = 0; i < 4; i++) {
        //   this.top_data_left.push(rows[i]);
        // }
        // this.top_data_right_one = rows[4];
        // this.top_data_right_two = rows[5];
      });
    },
    up(x, y) {
      return x.orderNum - y.orderNum;
    },
    up2(x, y) {
      return x.id - y.id;
    },
    getWebsiteInfo() {
      ListWebsiteInfo(this.queryPramas).then((res) => {
        let row = res.data.rows[0];
        this.header_logo = this.$api_url + row.logoOne;
        this.wb_name = row.wbName;
      });
    },
  },
  mounted() {
    if (localStorage.getItem("username") != null) {
      this.username = localStorage.getItem("username");
    }
    if (localStorage.getItem("loginStatus")) {
      let btn_login_status = document.getElementById("btn_login_status");

      btn_login_status.innerText = "退出";
    }

    // window.onbeforeunload = (e) => {
    //   return ''
    //   console.log(_this.$store.state.loginStatus);
    //   //刷新时弹出提示
    //   if (_this.$store.state.loginStatus) {
    //     return ''
    //     let btn_login_status = document.getElementById("btn_login_status");

    //     btn_login_status.innerText = "退出";
    //   }
    // };
  },
};
</script>

<style lang="scss" scoped>
.bny_header {
  height: 140px;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  .bny_header_top {
    height: 40px;
    background: #f2f2f2;
    color: #000;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        float: left;
        display: block;
        width: 100px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 12px;
        &:hover {
          cursor: pointer;

          text-decoration: underline;
          text-decoration-color: #558d66;
        }
        &:nth-last-child(-n + 3) {
          float: right;
          width: 60px;
          font-size: 12px;
        }
        &:nth-last-child(2) {
          margin-right: 30px;
        }
      }
    }
  }
  .bny_header_nav {
    height: 100px;
    width: 100%;
    background: url("../assets/imgs/header.png") no-repeat;
    /* background: #519f6d; */
    background-size: cover;
    text-align: left;
    a {
      text-decoration: none;
      color: #000;
      display: block;
      float: left;

      img {
        width: auto;
        max-height: 71px;
        margin-top: 20px;
        margin-left: 15px;
      }
    }
    .wb_name {
      display: block;
      float: left;
      width: auto;
      height: 100px;
      line-height: 100px;
      font-size: 24px;
      color: #f2f2f2;
      padding: 0 15px;
      margin-left: 20px;
    }
    .bny_header_nav_items {
      display: block;
      float: left;
      width: calc(100% - 550px);
      height: 100px;
      padding-right: 0px;
      text-align: right;
      .bny_showtime {
        margin-top: 20px;
        height: 30px;
        line-height: 30px;
        text-align: right;
        font-size: 12px;
        color: #f2f2f2;
        padding-right: 10px;
      }
      .bny_navs {
        height: 40px;
        font-size: 14px;
        color: #f2f2f2;
        position: relative;
        ul {
          position: absolute;
          right: 0;
          display: block;
          height: 40px;

          margin: 0;
          padding: 0;
          list-style: none;
          li {
            width: 70px;
            text-align: center;
            height: 4px;
            line-height: 40px;
            float: left;
            font-size: 14px;

            &:hover {
              cursor: pointer;
              text-decoration: underline;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
