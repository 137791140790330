<template>
  <el-dialog
    title="申请领用"
    :visible.sync="dialogVisible"
    width="50%"
    :close-on-click-modal="false"
    id="dialog_shenling"
    :show-close="true"
    :fullscreen="true"
    @close="close"
  >
    <el-form
      ref="formData"
      :model="formData"
      label-width="120px"
      :rules="rules"
    >
      <!-- 药品信息 -->
      <div class="section">
        <h3 class="section-title">药品信息</h3>
        <el-form-item label="药品选择:" prop="name">
          <el-autocomplete
            v-model="formData.name"
            :fetch-suggestions="querySearch"
            placeholder="请输入危化品搜索"
            @select="handleSelect"
            style="width: 100%"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="库存量:">
          <el-input v-model="current_stock" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="规格:">
          <el-input v-model="formData.spec" :disabled="true"></el-input>
        </el-form-item>
      </div>

      <!-- 领用情况 -->
      <div class="section">
        <h3 class="section-title">领用情况</h3>
        <el-form-item label="领用量:" prop="weight">
          <el-input-number v-model="formData.weight" :min="0"></el-input-number>
        </el-form-item>

        <el-form-item label="领用用途:" prop="purpose">
          <el-input v-model="formData.purpose" placeholder="请输入"></el-input>
        </el-form-item>

        <el-form-item label="领用人:" prop="create_by">
          <el-input v-model="create_name" :disabled="true"></el-input>
        </el-form-item>
      </div>
    </el-form>

    <div class="dialog-footer">
      <el-button @click="close" icon="el-icon-close">取消</el-button>
      <el-button type="primary" @click="handleSubmit" icon="el-icon-check"
        >确定</el-button
      >
    </div>
    <!-- 申领记录 -->
    <div class="sl_records">
      <h3 class="section-title">申领记录</h3>
      <el-table :data="tableData" style="width: 100%" border height="40vh">
        <el-table-column
          prop="uid"
          label="序号"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column prop="name" label="申领药品"></el-table-column>
        <el-table-column prop="spec" label="规格" width="100"></el-table-column>
        <el-table-column
          prop="weight"
          label="领用量"
          width="70"
        ></el-table-column>

        <!-- <el-table-column
          prop="purpose"
          label="领用用途"
          width="150"
        ></el-table-column> -->
        <el-table-column
          prop="create_time"
          label="申领时间"
          width="160"
        ></el-table-column>

        <el-table-column prop="status" label="申领状态">
          <template slot-scope="scope">
            <el-tooltip placement="top" effect="light">
              <div slot="content">
                <el-steps
                  direction="vertical"
                  :active="getActiveStep(scope.row)"
                >
                  <el-step
                    :title="scope.row.create_username"
                    description="
                      提交申领
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.group_user_id
                        ? scope.row.group_username
                        : '研究组负责人'
                    "
                    :description="
                      scope.row.group_user_id
                        ? scope.row.guser_result +
                          ' - ' +
                          scope.row.guser_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.guser_result)"
                  ></el-step>
                  <el-step
                    :title="
                      scope.row.admin_user_id
                        ? scope.row.admin_username
                        : '管理员'
                    "
                    :description="
                      scope.row.admin_user_id
                        ? scope.row.admin_result +
                          ' - ' +
                          scope.row.admin_aptime
                        : '未审核'
                    "
                    :status="getStepStatus(scope.row.admin_result)"
                  ></el-step>
                </el-steps>
              </div>
              <span>
                <span
                  style="color: #085"
                  v-if="scope.row.status === 0 || scope.row.status === null"
                  >待审核</span
                >
                <span style="color: #085" v-if="scope.row.status === 1"
                  >待确认</span
                >
                <span style="color: #085" v-if="scope.row.status === 2"
                  >待上传照片</span
                >
                <span style="color: #085" v-if="scope.row.status === 3"
                  >已上传照片</span
                >
                <span style="color: #085" v-if="scope.row.status === 4"
                  >已退回</span
                >
              </span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="handleDeleteClick(scope.row)"
              type="text"
              icon="el-icon-delete"
              size="small"
              v-if="
                scope.row.group_user_id == null || scope.row.group_user_id == ''
              "
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: Boolean,
    shenLingData: Object,
    isShowShenlingAndHeXiaoShenPin: Boolean,
  },
  data() {
    return {
      formData: {
        name: "",
        info_id: "",
        weight: "",
        purpose: "",
        create_by: null,
      },
      current_stock: "",
      create_name: localStorage.getItem("username"),
      names: [],
      allnames: [],
      rules: {
        name: [{ required: true, message: "请选择药品", trigger: "blur" }],
        weight: [{ required: true, message: "请输入领用量", trigger: "blur" }],
        purpose: [
          { required: true, message: "请输入领用用途", trigger: "blur" },
        ],
        create_by: [
          { required: true, message: "请输入领用人", trigger: "blur" },
        ],
      },

      tableData: [],
    };
  },
  watch: {
    "formData.weight": {
      handler(newValue) {
        // Perform validation or any other logic when weight changes
        if (newValue < 0) {
          this.formData.weight = 0; // Reset to 0 if negative
          this.$message.warning("领用量不能为负数");
          
        } else if (newValue > this.current_stock) {
          this.formData.weight = this.current_stock; // Rese
          this.$message.warning("领用量不能大于库存量");
         
        }
      },
      immediate: true, // Optional: triggers the handler immediately with the current value
    },
    shenLingData(obj) {
      this.formData.name = obj.name;
      this.formData.info_id = obj.id;
      this.formData.create_by = localStorage.getItem("userid");
      this.formData.purpose = obj.purpose;
      this.formData.spec = obj.spec;
      this.current_stock = obj.stock;
    },
    dialogVisible(val) {
      if (val) {
        this.getSlList();
      }
    },
  },
  methods: {
    getStepStatus(result) {
      if (result === "通过") return "success";
      if (result === "未通过") return "error";
      return "process";
    },
    getActiveStep(row) {
      if (row.admin_result === "通过" || row.admin_result === "未通过") {
        return 2;
      } else if (row.guser_result === "通过" || row.guser_result === "未通过") {
        return 1;
      } else {
        return 0;
      }
    },

    reset() {
      this.formData = {
        info_id: "",
        spec: "",
        weight: "",
        purpose: "",
        create_by: null,
        status: 0,
      };
    },
    querySearch(queryString, cb) {
      var restaurants = this.names;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      //this.formData.cas = item.cas;
      // 在这里处理选中的项目\
      //console.log(item);

      this.formData.info_id = item.id;

      this.current_stock = item.stock;

      this.formData.spec = item.spec;
      this.$refs.formData.validateField("name");

      //查询该药品是否已经申领过。申领过了。需要核销结束后，才能再次申领
    },
    handleMedicineSelect() {
      // 处理药品选择的逻辑
      console.log("选择药品");
    },
    close() {
      this.$parent.closeDialogShenling();
    },
    async getSlList() {
      // const res = await this.$db_select(
      //   this.$http,
      //   "xtbg_hazard_application",
      //   "*",
      //   "create_by=" +
      //     localStorage.getItem("userid") +
      //     " order by create_time DESC"
      // );
      // this.tableData = await Promise.all(
      //   res.map(async (row) => {
      //     if (row.group_userid) {
      //       row.group_username = (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.group_userid
      //         )
      //       )[0].name;
      //       row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);
      //     }
      //     if (row.admin_userid) {
      //       row.admin_username =
      //         (
      //           await this.$db_select(
      //             this.$http,
      //             "xtbg_user",
      //             "name",
      //             "id=" + row.admin_userid
      //           )
      //         )[0]?.name || "";
      //       row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);
      //     }

      //     return {
      //       ...row,
      //       name: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_hazard_info",
      //           "name",
      //           "id=" + row.info_id
      //         )
      //       )[0].name,
      //       create_username: (
      //         await this.$db_select(
      //           this.$http,
      //           "xtbg_user",
      //           "name",
      //           "id=" + row.create_by
      //         )
      //       )[0].name,
      //       create_time: this.$FormatDateToYMDHMS(row.create_time, true),
      //     };
      //   })
      // );
      this.loading = true;

      try {
        const userid = localStorage.getItem("userid2");

        // 获取符合条件的数据
        const res = await this.$db_select(
          this.$http,
          "xtbg_hazard_application",
          "*",
          "create_userid='" + userid + "' order by create_time DESC"
        );

        if (!res || res.length === 0) {
          this.tableData = [];
          this.loading = false;
          return;
        }

        const groupUserIds = [
          ...new Set(res.map((row) => row.group_user_id).filter(Boolean)),
        ];
        const adminUserIds = [
          ...new Set(res.map((row) => row.admin_user_id).filter(Boolean)),
        ];
        const createByIds = [
          ...new Set(res.map((row) => row.create_userid).filter(Boolean)),
        ];
        const infoIds = [
          ...new Set(res.map((row) => row.info_id).filter(Boolean)),
        ];

        // 合并所有用户 ID，避免重复查询
        const userIds = [
          ...new Set([...groupUserIds, ...adminUserIds, ...createByIds]),
        ];

        const useridses = userIds.map((row) => `'${row}'`).join(",");
        // return;

        // 批量查询所有相关信息
        const [users, infos] = await Promise.all([
          userIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_user",
                "id, name, userid",
                `userid IN (${useridses})`
              )
            : [],
          infoIds.length
            ? this.$db_select(
                this.$http,
                "xtbg_hazard_info",
                "id, name",
                `id IN (${infoIds.join(",")})`
              )
            : [],
        ]);
        // console.log(users);

        // 构建 ID 到名称的映射
        const userMap = Object.fromEntries(
          users.map((user) => [user.userid, user.name])
        );
        // console.log(userMap);
        const infoMap = Object.fromEntries(
          infos.map((info) => [info.id, info.name])
        );

        // 处理数据
        let row_index = 0;
        this.tableData = res.map((row) => {
          row_index++;
          row.uid = row_index;

          row.group_username = userMap[row.group_user_id] || "";
          row.guser_aptime = this.$FormatDateToYMDHMS(row.guser_aptime, true);

          row.admin_username = userMap[row.admin_user_id] || "";
          row.admin_aptime = this.$FormatDateToYMDHMS(row.admin_aptime, true);

          row.name = infoMap[row.info_id] || "";
          row.create_username = userMap[row.create_userid] || "";
          row.create_time = this.$FormatDateToYMDHMS(row.create_time, true);

          return row;
        });
      } catch (error) {
        console.error("加载数据时发生错误:", error);
        this.$message.error("加载数据时发生错误");
      } finally {
        this.loading = false;
      }
    },
    async handleDeleteClick(row) {
      if (
        this.isShowShenlingAndHeXiaoShenPin &&
        row.admin_userid != "" &&
        row.admin_userid != null
      ) {
        console.log(44);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      if (row.group_userid != "" && row.group_userid != null) {
        console.log(66);
        this.$message({
          type: "error",
          message: "不可删除",
        });

        return false;
      }

      //删除
      const deleteRes = await this.$db_delete(
        this.$http,
        "xtbg_hazard_application",
        "id=" + row.id
      );
      if (deleteRes.affectedRows > 0) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        await this.getSlList();
      } else {
        this.$message({
          type: "error",
          message: "删除失败",
        });
      }
    },
    async handleSubmit() {
      // 处理表单提交逻辑
      //console.log("表单提交", this.formData);
      //this.dialogVisible = false;
      //获取组的id
      let groupidRes = await this.$db_select(
        this.$http,
        "xtbg_hazard_studies",
        "groupid",
        "userid=" + localStorage.getItem("userid")
      );
      if (groupidRes) {
        this.formData.group_id = groupidRes[0].groupid;
      }

      this.formData.create_by = localStorage.getItem("userid");
      if (this.formData.weight == 0) {
        this.$message({
          type: "warning",
          message: "领用量不能为0",
        });
        return false;
      }
      if (this.formData.weight > this.current_stock) {
        this.$message({
          type: "warning",
          message: "领用量不能大于库存量",
        });
        return false;
      }

      delete this.formData.name;

      this.$refs.formData.validate(async (valid) => {
        if (valid) {
          //开始提交
          this.formData.create_time = this.$FormatDateToYMDHMS(
            new Date(),
            true
          );
          //console.log(this.formData);

          // 判断是否是负责人
          if (this.isShowShenlingAndHeXiaoShenPin) {
            this.formData.group_userid = localStorage.getItem("userid");
            this.formData.group_user_id = localStorage.getItem("userid2");
            this.formData.guser_result = "通过";
            this.formData.guser_remark = "通过";
            this.formData.guser_aptime = this.$FormatDateToYMDHMS(
              new Date(),
              true
            );
          }
          this.formData.create_userid=localStorage.getItem("userid2");

          const insertRes = await this.$db_insert(
            this.$http,
            "xtbg_hazard_application",
            this.formData
          );
          //console.log(insertRes);
          if (insertRes.affectedRows > 0) {
            this.reset();
            this.$message({
              type: "success",
              message: "申领成功!",
            });
            this.getSlList();
          } else {
            this.$message({
              type: "error",
              message: "申领失败!",
            });
          }
        } else {
          console.log("表单验证失败");
        }
      });
    },
  },
  async mounted() {
    this.reset();
    this.allnames = await this.$db_select(this.$http, "xtbg_hazard_info", "*");

    this.names = this.allnames.map((item) => {
      if (item.cas) {
        item.name = item.name + "(" + item.cas + ")";
        item.label = item.name + "(" + item.cas + ")";
      }

      return {
        id: item.id,
        value: item.name,
        label: item.label,
        cas: item.cas,
        stock: item.stock,
        spec: item.spec,
      };
    });

    //申领记录
  },
};
</script>

<style scoped>
.steps-tooltip {
  width: 300px;
  white-space: normal;
}
.section {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f7fa;
}

.section-title {
  font-size: 14px;
  font-weight: bold;
  color: #67c23a;
  margin-bottom: 10px;
  border-left: 4px solid #67c23a;
  padding-left: 10px;
}

.medicine-info {
  display: flex;
  align-items: center;
}

.medicine-info span {
  flex-grow: 1;
}

.inventory-status {
  color: red;
  margin-left: 10px;
}

.dialog-footer {
  text-align: right;
  padding: 10px 0;
}

.sl_records {
  /* display: flex;
  flex-direction: column; */
}
</style>
