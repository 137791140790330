<template>
  <el-dialog
    class="admin-form-dialog"
    :title="title"
    top="15vh"
    :visible.sync="visibleData"
    width="920px"
    :close-on-click-modal="false"
  >
    <el-form
      :model="filterUserForm"
      ref="roleForm"
      inline
      style="text-align: left"
    >
      <el-form-item label="用户姓名" prop="name">
        <el-select
          v-model="filterUserForm.name"
          filterable
          clearable
          placeholder="请选择姓名"
          @change="handleFilterUser"
        >
          <el-option
            v-for="(item, index) in userNameOptions"
            :key="'ueser_' + index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-input
          v-model="filterUserForm.name"
          clearable
          style="width: 160px"
        ></el-input> -->
      </el-form-item>
      <el-form-item label="部门名称" prop="dept_name">
        <!-- <el-input
          v-model="filterUserForm.dept_name"
          clearable
          style="width: 160px"
        ></el-input> -->
        <el-select
          v-model="filterUserForm.dept_name"
          filterable
          clearable
          placeholder="请选择部门"
        >
          <el-option
            v-for="(item, index) in deptOptions"
            :key="'dept_' + index"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          style="width: 100px"
          icon="el-icon-s-operation"
          plain
          @click="handleFilterUser"
        >
          筛 选
        </el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleReset">重 置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      ref="myTable"
      v-loading="loading"
      :header-row-style="headerRowStyle"
      :header-cell-style="headerCellStyle"
      :cell-style="cellStyle"
      border
      :style="tabbleStyle"
      @selection-change="handleSelectionUser"
      @row-click="onRowClick"
      height="500"
    >
      <el-table-column
        v-if="multiple"
        type="selection"
        align="center"
        width="55"
      >
      </el-table-column>
      <el-table-column type="index" align="center" label="序号" width="50">
      </el-table-column>
      <el-table-column prop="name" align="center" label="用户姓名">
      </el-table-column>
      <el-table-column prop="phone" align="center" label="电话">
      </el-table-column>
      <el-table-column prop="email" align="center" label="邮箱">
      </el-table-column>
      <el-table-column prop="dept_name" align="center" label="所属部门">
      </el-table-column>
      <el-table-column v-if="!multiple" align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            slot="reference"
            type="primay"
            icon="el-icon-plus"
            size="mini"
            @click="handleSelect(scope.row)"
          >
            选 择
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 10px; float: right; padding-right: 0"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="page.total"
    >
    </el-pagination>
    <div v-if="multiple" slot="footer" style="margin-top: 52px">
      <el-button @click="visibleData = false">取 消</el-button>
      <el-button type="primary" @click="handleOk" :disabled="!selectRows.length"
        >确 定</el-button
      >
    </div>
    <div v-else style="margin-top: 52px"></div>
  </el-dialog>
</template>

<script>
import { dbquery, getDataTotal } from "../proposeRoleUser.js";
const tableName = "xtbg_user";
export default {
  name: "SelectUser",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "选择用户",
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 多选时，已选中的用户
    selectedUsers: {
      type: Array,
      default: () => [],
    },
    // 多选时，选中的用户的key
    selectRowsKey: {
      type: String,
      default: "id",
    },
    // 多选时，选中的用户的value(数组、全部的value)
    selectRowsValue: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sxbool: false,

      userNameOptions: [],
      deptOptions: [],
      visibleData: this.visible,
      filterUserForm: {
        name: "",
        dept_name: "",
      },
      loading: false,
      tableData: [],
      page: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      queryWrapper: "1=1",
      lastQueryWrapper: "",
      selectRows: [],
    };
  },
  computed: {
    tabbleStyle() {
      return {
        borderColor: "#D1EAD1",
      };
    },
    cellStyle() {
      return {
        borderLeftColor: "#E0EFE030",
        borderRightColor: "#E0EFE030",
        borderTopColor: "#D1EAD1",
        borderBottomColor: "#D1EAD1",
      };
    },
    headerRowStyle() {
      return {};
    },
    headerCellStyle() {
      return {
        background: "#E0EFE0",
        color: "#3D3D3D",
        borderColor: "#D1EAD1",
        fontFamily: "Source Han Sans",
        fontSize: "14px",
      };
    },
  },
  watch: {
    visible(val) {
      this.visibleData = val;
      if (val) {
        this.getData(true);

        this.getDeptNames();

        this.getUsernNames();
      }
    },
    visibleData(val) {
      this.$emit("update:visible", val);
    },
  },
  methods: {
    handleReset() {
      this.sxbool = false;
      this.filterUserForm = {
        name: "",
        dept_name: "",
      };
      this.getData(true);
    },

    async dbquery(sqlStr) {
      return dbquery(this.$http, sqlStr);
    },
    async getDataTotal(tName) {
      const totalres = await this.$db_select(
        this.$http,
        tName,
        "count(*) as total",
        "1=1"
      );

      return totalres.length > 0 ? totalres[0].total : 0;
    },

    selectName() {},

    //获取用户列表
    async getUsernNames() {
      const role_name = localStorage.getItem("propose_rolename");
      const roleid_zgdb = this.$congresConfig.congressRole.roleid_zgdb;

      let userSqlStr = "";
      // if (role_name == "提案组") {
      //   userSqlStr =
      //     "SELECT * FROM xtbg_user WHERE id in (SELECT user_id FROM xtbg_congress_role_user WHERE role_id=" +
      //     roleid_zgdb +
      //     ")";
      // } else {
      //   userSqlStr = "select name from xtbg_user";
      // }
      //userSqlStr = "select name from xtbg_user";

      const users = await this.$db_select(
        this.$http,
        "xtbg_user",
        "name",
        "1=1"
      );

      this.userNameOptions = users.map((item) => ({
        label: item.name,
        value: item.name,
      }));
    },

    //获取所有部门
    async getDeptNames() {
      // const detpSqlStr = "select dept_id,dept_name from sys_dept";
      //const depts = await this.$db_select(this.$http, "sys_dept", "dept_id,dept_name", "1=1");
      const depts = await this.$deptList(this.$http);

      this.deptOptions = depts.map((item) => ({
        label: item.dept_name,
        value: item.dept_name,
      }));
    },

    async getData(current0) {
      this.loading = true;
      if (current0) {
        this.page.currentPage = 1;
        this.tableData = [];
      }

      const role_name = localStorage.getItem("propose_rolename");
      const roleid_zgdb = this.$congresConfig.congressRole.roleid_zgdb;
      const { currentPage, pageSize } = this.page;

      //let param = "";
      let where = "";

      if (!this.sxbool) {
        where = `1=1 ORDER BY create_time DESC LIMIT ${pageSize} OFFSET ${
          (currentPage - 1) * pageSize
        }`;
        this.page.total = await this.getDataTotal("xtbg_user");
      } else {
        where = this.queryWrapper;
        const totalres = await this.$db_select(
          this.$http,
          "xtbg_user",
          "count(*) as total",
          where
        );
        this.page.total = totalres.length > 0 ? totalres[0].total : 0;
      }

      // const res = await this.dbquery(param);

      const res = await this.$db_select(this.$http, "xtbg_user", "*", where);

      this.tableData = res || [];
      this.$nextTick(() => {
        if (this.multiple) {
          this.tableData.map((item) => {
            let obj = this.selectRowsValue.find(
              (v) => v === item[this.selectRowsKey]
            );
            if (obj) {
              this.$refs.myTable.toggleRowSelection(item, true);
            }
          });
        }
      });
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },

    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getData(true);
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getData();
    },
    handleFilterUser() {
      this.sxbool = true;

      // console.log(this.filterUserForm);
      const { name, dept_name } = this.filterUserForm;
      let queryWrapper = `1=1`;
      if (name) {
        queryWrapper += ` AND name LIKE '%${name}%'`;
      }
      if (dept_name) {
        queryWrapper += ` AND dept_name LIKE '%${dept_name}%'`;
      }
      if (!name && !dept_name) {
        queryWrapper = "1=1";
      }
      console.log(queryWrapper);
      this.queryWrapper = queryWrapper;
      this.getData(true);
    },
    handleSelectionUser(val) {
      this.selectRows = val.map((item) => {
        return {
          user_id: item.id,
          userid:item.userid,
        };
      });
    },
    handleSelect(row) {
      this.$emit("select", [row]);
      this.visibleData = false;
    },
    handleOk() {
      // 根据tableData，筛选出取消选中的数据
      // let cancelSelectRows = this.tableData.filter((item) => {
      //   let obj = this.selectRows.find(
      //     (v) => v[this.selectRowsKey] === item[this.selectRowsKey]
      //   );
      //   return !obj;
      // });
      // 当前页选中的数据，当前页取消选中的数据
      //console.log(this.selectRows);
      this.$emit("select", this.selectRows);

      this.visibleData = false;
    },
    onRowClick(row) {
      if (!this.multiple) {
        return "";
      }
      this.$refs.myTable.toggleRowSelection(row);
    },
  },
};
</script>
